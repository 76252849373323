import React from 'react';
import { Col, Form, FormGroup, Row } from 'react-bootstrap';
import {
  ExtraFieldValueFragment,
  FieldType,
  ObjectExtraFieldsFragment,
  RecordTypeFragment,
} from '../../../__generated__/graphql';
import { FieldValue } from '../../EmployeeData/FieldValue';
import Skeleton from 'react-loading-skeleton';
import { recordTypeFormKey, toInputValue } from '../logic';
import i18next from 'i18next';

type Props = {
  objectExtraFields: ObjectExtraFieldsFragment;
  values: readonly ExtraFieldValueFragment[];
  recordType: RecordTypeFragment;
  columnsCount?: number;
};

export const ExtraFieldsViewAreaContent: React.FC<Props> = ({
  objectExtraFields,
  values,
  recordType,
  columnsCount = 1,
}) => {
  let valuesToRender = [];
  if (!recordType.IsMaster) {
    valuesToRender.push({
      inputValue: recordType.Label,
      fieldType: FieldType.Picklist,
      label: i18next.t('extraFields.recordType'),
      fieldApiName: recordTypeFormKey,
    });
  }

  valuesToRender = [
    ...valuesToRender,
    ...objectExtraFields.form.reduce(
      (acc, formInfo) => {
        const value = values.find(
          (v) => v.fieldApiName === formInfo.fieldApiName,
        );
        if (value) {
          acc.push({
            fieldApiName: formInfo.fieldApiName,
            inputValue: toInputValue(value),
            label: formInfo?.fieldInfo?.label,
            fieldType: formInfo?.fieldInfo?.type,
          });
        }
        return acc;
      },
      new Array<{
        fieldApiName: string;
        inputValue: string | null;
        fieldType: FieldType;
        label: string;
      }>(),
    ),
  ];

  const rowsCount = Math.ceil(valuesToRender.length / columnsCount);
  return (
    <>
      {Array.from(Array(rowsCount).keys()).map((rowIndex) => {
        return (
          <Row key={rowIndex.toString()}>
            {valuesToRender
              .slice(rowIndex * columnsCount, (rowIndex + 1) * columnsCount)
              .map(({ label, inputValue, fieldApiName, fieldType }) => {
                return (
                  <Col key={fieldApiName}>
                    <FormGroup>
                      <Form.Label className="fw-bold">{label}</Form.Label>
                      <p>
                        <FieldValue
                          type={fieldType}
                          options={[]}
                          value={inputValue}
                        />
                      </p>
                    </FormGroup>
                  </Col>
                );
              })}
          </Row>
        );
      })}{' '}
    </>
  );
};

export const Loading: React.FC<{ columnsCount: number }> = ({
  columnsCount,
}) => {
  const rowsCount = Math.ceil(6 / columnsCount);
  return (
    <>
      {Array.from(Array(rowsCount).keys()).map((rowIndex) => {
        return (
          <Row key={rowIndex.toString()}>
            {Array.from(Array(columnsCount).keys()).map((columnIndex) => {
              return (
                <Col key={columnIndex.toString()}>
                  <Skeleton height={40} className="mt-3" />
                </Col>
              );
            })}
          </Row>
        );
      })}
    </>
  );
};
