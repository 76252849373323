import * as React from 'react';
import FlairIcon, { FlairIconProps } from '../../atoms/FlairIcon';
import './FlairButtonIcon.css';

type Variant = 'primary' | 'text';

type Props = FlairIconProps & {
  isActive?: boolean;
  isProcessing?: boolean; // not implemented ui state
  onClick?: () => void;
  dataTestId?: string;
  variant?: Variant;
};

const FlairButtonIcon = React.forwardRef<SVGSVGElement, Props>(
  (
    {
      isActive,
      isProcessing,
      onClick,
      dataTestId,
      variant = 'primary',
      ...restProps
    },
    ref,
  ) => {
    const classNames = [
      'flair-btn-icon-container',
      variant,
      isActive ? 'active' : '',
      'd-flex',
      'align-items-center',
      'rounded-1',
      'p-1',
      restProps.className,
    ].join(' ');

    return (
      <div
        className={classNames}
        role="button"
        onClick={onClick}
        data-testid={dataTestId}>
        <FlairIcon {...restProps} ref={ref} data-testid="flair-icon" />
      </div>
    );
  },
);

export default FlairButtonIcon;
