import { TFunction } from 'i18next';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServerError from '../../../../components/ServerError';
import { useEmployeeAccrualPolicyIntervalReduce } from '../../hooks/useEmployeeAccrualPolicyIntervalReduce';
import { useHourlyAbsenceAmountFormatter } from '../../hooks/useHourlyAbsenceAmountFormatter';
import {
  AbsenceCategoriesGroup,
  AbsenceRequestPolicy,
  AbsenceType,
  EmployeeAbsenceRequestPolicy,
  Maybe,
  MyAbsenceCategoryByIdQuery,
  useMyAbsenceCategoryByIdQuery,
} from '../../__generated__/graphql';
import AbsenceCategoryIcon from '../AbsenceCategoryIcon';
import ModalSidebar from '../ModalSidebar';
import AbsenceCategorySelect from './AbsenceCategorySelect';
import { AbsenceFormProps } from './AbsenceFormProps';
import Loading from './Loading';
import { getUniqueGroupsByCategories } from '../../pages/Absences/GroupCategories';
import { AbsenceRequestPolicyRulesCard } from './AbsenceRequestPolicyRulesCard';
import { EmptyState } from '../EmptyStateCard';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import { PureQueryOptions } from '@apollo/client';
import { DailyAbsenceFormWithExtraFields } from './DailyAbsenceFormWithExtraFields';
import { HourlyAbsenceFormWithExtraFields } from './HourlyAbsenceFormWithExtraFields';

type AbsenceCategory = {
  id: string;
  Name: string;
  order: number;
  icon: string | null;
  type: AbsenceType;
  unlimited: boolean;
  withoutBalance: boolean;
  availableAmount: number;
  group: Maybe<Pick<AbsenceCategoriesGroup, 'Id' | 'Name' | 'flair__Order__c'>>;
  policies: ReadonlyArray<
    Pick<EmployeeAbsenceRequestPolicy, 'Id'> & {
      absenceRequestPolicy: Pick<AbsenceRequestPolicy, 'Id' | 'Name'>;
    }
  >;
};

type Props = {
  show: boolean;
  onClose: () => void;
  absenceCategoryId: string;
  absenceCategories: AbsenceCategory[];
  onSuccess?: () => void;
  refetchQueries?: PureQueryOptions[];
};

const AbsenceForm: React.FC<
  Pick<
    Props,
    'absenceCategoryId' | 'onClose' | 'onSuccess' | 'refetchQueries'
  > & {
    absenceCategoriesGroupSelect: React.ReactNode;
    absenceCategorySelect: React.ReactNode;
  }
> = ({
  absenceCategoryId,
  absenceCategoriesGroupSelect,
  absenceCategorySelect,
  onClose,
  onSuccess,
  refetchQueries,
}) => {
  const { data, loading, error } = useMyAbsenceCategoryByIdQuery({
    variables: { id: absenceCategoryId },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <Loading
        absenceCategoriesGroupSelect={absenceCategoriesGroupSelect}
        absenceCategorySelect={absenceCategorySelect}
      />
    );
  }

  if (error || !data || !data.myAbsenceCategoryById) {
    return <ServerError />;
  }

  return (
    <AbsenceFormInputs
      onClose={onClose}
      onSuccess={onSuccess}
      absenceCategoriesGroupSelect={absenceCategoriesGroupSelect}
      absenceCategorySelect={absenceCategorySelect}
      selectedAbsenceCategory={data.myAbsenceCategoryById}
      refetchQueries={refetchQueries}
    />
  );
};

const AbsenceFormInputs: React.FC<
  Pick<Props, 'onClose' | 'onSuccess' | 'refetchQueries'> & {
    absenceCategoriesGroupSelect: React.ReactNode;
    absenceCategorySelect: React.ReactNode;
    selectedAbsenceCategory: NonNullable<
      MyAbsenceCategoryByIdQuery['myAbsenceCategoryById']
    >;
  }
> = ({
  onClose,
  onSuccess,
  absenceCategoriesGroupSelect,
  absenceCategorySelect,
  selectedAbsenceCategory,
  refetchQueries,
}) => {
  const reduceAccrualPolicyInterval = useEmployeeAccrualPolicyIntervalReduce();
  const [selectedRequestPolicyId, setSelectedRequestPolicyId] = useState<
    string | null
  >(selectedAbsenceCategory.absenceRequestPolicies[0]?.Id ?? null);

  const absenceRequestPolicySelect =
    selectedAbsenceCategory.absenceRequestPolicies.length > 1 ? (
      <AbsenceCategorySelect
        options={selectedAbsenceCategory.absenceRequestPolicies.map(
          (policy) => {
            return {
              value: policy.Id,
              label: policy.absenceRequestPolicy.Name,
              icon: <FlairIcon icon="clipboard-outline" />,
            };
          },
        )}
        value={selectedRequestPolicyId}
        onSelect={(option) => setSelectedRequestPolicyId(option?.value ?? null)}
      />
    ) : undefined;

  const selectedRequestPolicy =
    selectedAbsenceCategory.absenceRequestPolicies.find(
      (eabrp) => eabrp.Id === selectedRequestPolicyId,
    );

  const categoryType = selectedAbsenceCategory.category.flair__Type__c;

  const formProps: AbsenceFormProps = {
    categoryId: selectedAbsenceCategory.Id,
    categoryName: selectedAbsenceCategory.category.Name,
    policyId: selectedRequestPolicyId,
    unlimited: selectedAbsenceCategory.flair__Unlimited__c,
    withoutBalance: selectedAbsenceCategory.category.flair__Without_Balance__c,
    onClose,
    onSuccess,
    specialRules:
      selectedAbsenceCategory.category.flair__Special_Rules__c ?? null,
    absenceCategoriesGroupSelect: absenceCategoriesGroupSelect,
    absenceCategorySelect,
    absenceRequestPolicySelect,
    absenceRequestPolicyRules: selectedRequestPolicy ? (
      <AbsenceRequestPolicyRulesCard
        absenceRequestPolicy={selectedRequestPolicy.absenceRequestPolicy}
        absenceType={categoryType}
      />
    ) : undefined,
    accrualPolicyInterval: reduceAccrualPolicyInterval(
      selectedAbsenceCategory.accrualPolicies,
    ),
    requireDocumentUpload:
      selectedRequestPolicy?.absenceRequestPolicy.flair__Document_Upload__c ??
      false,
    minAmountForDocumentUpload:
      selectedRequestPolicy?.absenceRequestPolicy
        .flair__Min_Amount_For_Document_Upload__c ?? null,
    requireNote:
      selectedRequestPolicy?.absenceRequestPolicy.flair__Require_Note__c ??
      false,
    notePlaceholder:
      selectedRequestPolicy?.absenceRequestPolicy.flair__Note_Placeholder__c ??
      null,
    refetchQueries,
  };

  switch (categoryType) {
    case AbsenceType.Daily:
      return <DailyAbsenceFormWithExtraFields {...formProps} />;
    case AbsenceType.Hourly:
      return <HourlyAbsenceFormWithExtraFields {...formProps} />;
    case AbsenceType.Unknown:
      return <></>;
  }
};

const useBuildOptionLabel = () => {
  const hourlyFormatter = useHourlyAbsenceAmountFormatter();

  return useCallback(
    (absenceCategory: AbsenceCategory, t: TFunction) => {
      if (absenceCategory.unlimited) {
        return absenceCategory.Name;
      }

      if (absenceCategory.withoutBalance) {
        return absenceCategory.Name;
      }

      const isHourly = absenceCategory.type === AbsenceType.Hourly;
      const noCredits = absenceCategory.availableAmount <= 0;
      const i18nKey = `requestAbsence.form.absenceCategory.label.${
        isHourly ? 'hourly' : 'daily'
      }${noCredits ? 'NoCredits' : ''}`;

      return isHourly
        ? t(i18nKey, {
            name: absenceCategory.Name,
            amount: hourlyFormatter(absenceCategory.availableAmount),
            interpolation: { escapeValue: false },
          })
        : t(i18nKey, {
            name: absenceCategory.Name,
            count: absenceCategory.availableAmount,
            interpolation: { escapeValue: false },
          });
    },
    [hourlyFormatter],
  );
};

const Content: React.FC<
  Pick<
    Props,
    | 'absenceCategoryId'
    | 'onClose'
    | 'onSuccess'
    | 'absenceCategories'
    | 'refetchQueries'
  >
> = ({
  absenceCategoryId,
  onClose,
  onSuccess,
  absenceCategories,
  refetchQueries,
}) => {
  const { t } = useTranslation();
  const [selectedAbsenceCategoryId, setAbsenceCategoryId] =
    useState<string>(absenceCategoryId);

  const groups = useMemo(() => {
    return getUniqueGroupsByCategories(absenceCategories).map((g) => ({
      ...g,
      name: t('categoriesGroup.groupName', {
        group_name: g.name,
        interpolation: { escapeValue: false },
      }),
    }));
  }, [absenceCategories, t]);

  const groupInitialValue = useMemo(() => {
    let groupInitialValue = null;
    //no pre-selected category => the selected group will be the first 1
    if (!absenceCategoryId) {
      groupInitialValue = groups[0]?.id || null;
    } else {
      const initialSelectedCategory = absenceCategories.find(
        (c) => c.id === absenceCategoryId,
      );
      const categoryGroupId = initialSelectedCategory?.group?.Id;

      //the pre-selected category has a group
      if (!!categoryGroupId) {
        groupInitialValue =
          groups.find((g) => g.id === categoryGroupId)?.id || null;
      } else {
        groupInitialValue = absenceCategoryId;
      }
    }
    return groupInitialValue;
  }, [absenceCategoryId, groups, absenceCategories]);

  const [selectedGroupId, setGroupId] = useState<string | null>(
    groupInitialValue,
  );
  const buildOptionLabel = useBuildOptionLabel();
  const categoriesOptions = useMemo(
    () =>
      absenceCategories
        .filter((c) => c.group?.Id === selectedGroupId)
        .map((c) => ({
          value: c.id,
          label: buildOptionLabel(c, t),
          icon: <AbsenceCategoryIcon icon={c.icon} />,
        })),
    [t, absenceCategories, buildOptionLabel, selectedGroupId],
  );

  const absenceCategorySelect = useMemo(
    () => (
      <AbsenceCategorySelect
        options={categoriesOptions}
        value={selectedAbsenceCategoryId}
        onSelect={(option) => setAbsenceCategoryId(option?.value ?? '')}
      />
    ),
    [categoriesOptions, selectedAbsenceCategoryId, setAbsenceCategoryId],
  );

  const groupsOptions = groups.map((g) => ({
    value: g.id,
    label: g.name,
    icon: <FlairIcon icon="folder-outline" />,
  }));
  const categoriesWithNoGroups = absenceCategories
    .filter((c) => !c.group)
    .map((c) => ({
      value: c.id,
      label: buildOptionLabel(c, t),
      icon: <AbsenceCategoryIcon icon={c.icon} />,
    }));
  const groupsWithNoGroups = useMemo(
    () => [...groupsOptions, ...categoriesWithNoGroups],
    [groupsOptions, categoriesWithNoGroups],
  );

  const groupsSelect = useMemo(
    () => (
      <AbsenceCategorySelect
        options={groupsWithNoGroups}
        value={selectedGroupId}
        onSelect={(option) => {
          const selectedOption = option?.value ?? '';
          const isGroupSelected = groups.some((g) => g.id === selectedOption); //to check if they selected a group or "ungrouped category"
          if (isGroupSelected) {
            setGroupId(selectedOption);
            setAbsenceCategoryId(
              absenceCategories.find((c) => c.group?.Id === selectedOption)
                ?.id || '',
            );
          } else {
            setGroupId(selectedOption);
            setAbsenceCategoryId(selectedOption);
          }
        }}
      />
    ),
    [
      groupsWithNoGroups,
      selectedGroupId,
      absenceCategories,
      groups,
      setGroupId,
      setAbsenceCategoryId,
    ],
  );

  if (!absenceCategories.length) {
    return (
      <div className="d-flex flex-column align-items-center p-3">
        <EmptyState title={t('requestAbsence.sidebar.noAbsenceCategories')} />
      </div>
    );
  }

  return (
    <AbsenceForm
      absenceCategoryId={selectedAbsenceCategoryId}
      absenceCategoriesGroupSelect={groupsSelect}
      absenceCategorySelect={
        selectedGroupId === selectedAbsenceCategoryId
          ? null
          : absenceCategorySelect
      }
      onClose={onClose}
      onSuccess={onSuccess}
      refetchQueries={refetchQueries}
    />
  );
};

const RequestAbsenceSidebar: React.FC<Props> = ({
  show,
  onClose,
  absenceCategoryId,
  absenceCategories,
  onSuccess,
  refetchQueries,
}) => {
  const { t } = useTranslation();

  return (
    <ModalSidebar
      show={show}
      onClose={onClose}
      header={t('requestAbsence.sidebar.title')}
      content={
        <Content
          absenceCategoryId={absenceCategoryId}
          onClose={onClose}
          onSuccess={onSuccess}
          absenceCategories={absenceCategories}
          refetchQueries={refetchQueries}
        />
      }
    />
  );
};

export default RequestAbsenceSidebar;
