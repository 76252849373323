import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import { FormGroup } from './FormGroup';
import InputError from './InputError';
import InputLabel from './InputLabel';
import ScrollToError from './ScrollToError';
import { getError } from './utils';
import { InfoHint } from '../hint/InfoHint';

type InputType =
  | 'email'
  | 'password'
  | 'select'
  | 'textarea'
  | 'time'
  | 'number'
  | 'checkbox'
  | 'url'
  | 'tel';

type Props = InputProps | TextAreaProps | NumberProps;

type InputProps = {
  label?: string;
  name: string;
  placeholder?: string;
  type?: InputType;
  children?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  labelComponent?: ReactNode;
  autoFocus?: boolean;
  size?: 'sm' | 'lg' | 'md';
  className?: string;
  hint?: ReactNode;
  onChange?: (value: string) => void;
  style?: CSSProperties;
  defaultValue?: string;
};

type TextAreaProps = InputProps & {
  type: 'textarea';
  minRows?: number;
  maxRows?: number;
  maxLength?: number;
};

type NumberProps = InputProps & {
  type: 'number';
  min?: number;
  max?: number;
  step?: string;
};

const formControlType = (type?: InputType) => {
  switch (type) {
    case 'select':
    case 'textarea':
      return { as: type };
    default: {
      return { type: type };
    }
  }
};

type ControlledInputProps = Omit<
  InputProps,
  'label' | 'labelComponent' | 'required'
>;

type ControlledTextAreaProps = Omit<
  TextAreaProps,
  'label' | 'labelComponent' | 'required'
>;

export const ControlledInput: React.FC<
  ControlledInputProps | ControlledTextAreaProps
> = ({ name, type, disabled, className, onChange, ...rest }) => {
  const {
    control,
    errors,
    formState: { isSubmitting },
  } = useFormContext();

  const handleChange = useCallback(
    ([event]) => {
      onChange && onChange((event.target as HTMLInputElement).value);
      return event.target.value;
    },
    [onChange],
  );

  const error = getError(name, errors);

  const inputref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (rest.autoFocus) {
      inputref.current?.focus();
    }
  }, [rest.autoFocus]);

  return (
    <>
      <Controller
        as={
          type === 'textarea' ? (
            <TextareaAutosize
              disabled={disabled}
              readOnly={isSubmitting}
              className={[
                'form-control',
                className,
                error ? 'is-invalid' : '',
              ].join(' ')}
            />
          ) : (
            <Form.Control
              disabled={disabled}
              isInvalid={!!error}
              readOnly={isSubmitting}
              className={className}
              {...formControlType(type)}
              ref={inputref}
            />
          )
        }
        onChange={handleChange}
        name={name}
        control={control}
        {...rest}
      />
      <InputError error={error} />
    </>
  );
};

export const Input: React.FC<Props> = ({
  label,
  name,
  required,
  hint,
  labelComponent,
  ...rest
}) => {
  const ref = useRef(null);

  return React.useMemo(
    () => (
      <FormGroup ref={ref}>
        {labelComponent ? (
          labelComponent
        ) : (
          <InputLabel
            label={label}
            required={required}
            hint={
              hint ? (
                <InfoHint className="mt-2" text={String(hint)} />
              ) : undefined
            }
          />
        )}
        <ControlledInput name={name} {...rest} />
        <ScrollToError name={name} inputContainerRef={ref} />
      </FormGroup>
    ),
    [label, labelComponent, name, required, rest, ref, hint],
  );
};
