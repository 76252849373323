import { format, isEqual, startOfYear } from 'date-fns';
import { toISODateOnly } from '../../../../../utils/dateUtils';
import {
  RequestAbsence_AllowanceFragment,
  RequestAbsence_WithdrawalAllowanceFragment,
  RequestAbsence_YearlySummaryFragment,
} from '../../../__generated__/graphql';
import {
  createYearPeriods,
  Period,
} from '../../../pages/Absences/components/CategoryAccordionBody/helpers';
import { WithdrawalAllowance } from './types';
import i18next from 'i18next';

export const calculateWithdrawalAllowancesForYearlyPeriods = (
  withdrawalAllowances: ReadonlyArray<RequestAbsence_WithdrawalAllowanceFragment>,
  summary: RequestAbsence_YearlySummaryFragment,
  now: Date,
): WithdrawalAllowance[] => {
  const allowances = [
    summary.previousPeriod.allowances,
    summary.currentPeriod.allowances,
    summary.nextPeriod.allowances,
  ];
  const availables: number[] = allowances.map(getAvailable);
  const systemWithdrawals = withdrawalAllowances.filter((x) => x.isSystem);
  const nonSystemWithdrawals = withdrawalAllowances.filter((x) => !x.isSystem);
  const requesting: number[] = allowances.map((allowances) =>
    getRequesting(allowances, nonSystemWithdrawals),
  );

  const periods = createYearPeriods(summary);
  const periodFormatFunc = getFormatPeriodFunction(periods[1].start, now);
  const result: WithdrawalAllowance[] = periods.map((_, index) => ({
    startDate: toISODateOnly(periods[index].start),
    period: periodFormatFunc(periods[index]),
    before: availables[index],
    requesting: requesting[index],
    after: availables[index] - requesting[index],
  }));

  const systemRequesting = systemWithdrawals.reduce(
    (acc, withdrawal) => acc + withdrawal.withdrawalAmount,
    0,
  );
  if (systemRequesting > 0) {
    result.push({
      startDate: '',
      period: i18next.t('requestAbsence.requestingAmount.allowances.system'),
      before: 0,
      requesting: systemRequesting,
      after: 0,
    });
  }

  return result.filter((x) => x.requesting > 0);
};

const getAvailable = (
  allowances: readonly RequestAbsence_AllowanceFragment[] | null,
): number => {
  if (!allowances) {
    return 0;
  }
  return allowances.reduce(
    (acc, allowance) => acc + getAllowanceAvailable(allowance),
    0,
  );
};

const getRequesting = (
  periodAllowances: readonly RequestAbsence_AllowanceFragment[] | null,
  withdrawalAllowances: ReadonlyArray<RequestAbsence_WithdrawalAllowanceFragment>,
): number => {
  if (!periodAllowances) {
    return 0;
  }
  const allowanceIds = periodAllowances.reduce(
    (acc, allowance) =>
      allowance.absenceAllowanceId
        ? acc.add(allowance.absenceAllowanceId)
        : acc,
    new Set<string>(),
  );
  return withdrawalAllowances.reduce(
    (acc, { id, withdrawalAmount }) =>
      allowanceIds.has(id) ? acc + withdrawalAmount : acc,
    0,
  );
};

const getAllowanceAvailable = (
  allowance: RequestAbsence_AllowanceFragment,
): number =>
  allowance.accrued - allowance.expired - allowance.used - allowance.lost;

type FormatPeriodFuncT = (period: Period) => string;

const getFormatPeriodFunction = (
  curPeriodStartDate: Date,
  now: Date,
): FormatPeriodFuncT => {
  if (isEqual(startOfYear(now), curPeriodStartDate)) {
    // standar yearly period
    return (period: Period) => format(period.start, 'yyyy');
  }
  // custom yearly period
  return (period: Period) =>
    `${format(period.start, 'MMM yyyy')} - ${format(period.end, 'MMM yyyy')}`;
};
