import { Connection } from 'jsforce';
import _, { isEmpty } from 'lodash';

export type EmployeeDataMapperOptions<TState, TFieldType> = {
  mapState: (src: unknown) => TState;
  mapFieldType: (src: unknown) => TFieldType;
};

// todo: move to internalShared
export interface EmployeeDataRepositoryBase {
  findById: <TState, TFieldType extends FieldType>(
    id: string,
    locale: string,
  ) => Promise<EmployeeData<TState, TFieldType>>;
  findByIdForManager: (
    id: string,
    locale: string,
  ) => Promise<EmployeeDataSalesforceResponse>;
  updateLanguage: (id: string, language: string) => Promise<string>;
  update: <TFieldType extends FieldType>(
    id: string,
    fields: UpdateFieldValue<TFieldType>[],
  ) => Promise<string>;
  updateWithoutApproval: <TFieldType extends FieldType>(
    id: string,
    fields: UpdateFieldValue<TFieldType>[],
  ) => Promise<string>;
  getFieldValues: (employeeId: string, fieldNames: string[]) => Promise<any[]>;
}

export const buildEmployeeDataRepositoryBase = (
  conn: () => Connection,
): EmployeeDataRepositoryBase => {
  return {
    findById: <TState, TFieldType>(
      id: string,
      locale: string,
    ): Promise<EmployeeData<TState, TFieldType>> => {
      return conn()
        .apex.get<EmployeeDataSalesforceResponse>(
          '/flair/hub/employees/' +
            id +
            '?locale=' +
            encodeURIComponent(mapLocale(locale)),
        )
        .then((response) => {
          return response;
        })
        .then(filterPicklistValues(conn))
        .then((x) => mapFromEmployeeDataResult<TState, TFieldType>(x, locale));
    },
    findByIdForManager: (id, locale) =>
      conn()
        .apex.get<EmployeeDataSalesforceResponse>(
          '/flair/hub/manager/employees/' +
            id +
            '?locale=' +
            encodeURIComponent(mapLocale(locale)),
        )
        .then(filterPicklistValues(conn)),
    updateLanguage: (id, language) =>
      conn()
        .sobject('flair__Employee__c')
        .update({
          Id: id,
          flair__Employee_Language__c: language,
        })
        .then(() => id),
    update: (id, fields) =>
      conn()
        .apex.patch('/flair/hub/employees/' + id, toUpdateObject(fields))
        .then(() => id),
    updateWithoutApproval: (id, fields) =>
      conn()
        .sobject('flair__Employee__c')
        .update({
          Id: id,
          ...toUpdateObject(fields),
        })
        .then(() => id),
    getFieldValues: (employeeId: string, fieldNames: string[]) =>
      conn()
        .sobject('flair__Employee__c')
        .find(
          {
            Id: employeeId,
          },
          fieldNames,
        )
        .then((response) => {
          const responseItem: object = response.length === 1 ? response[0] : {};
          return fieldNames.map((fieldName) =>
            _.get(responseItem, fieldName, undefined),
          );
        }),
  };
};

export type EmployeeData<TState, TFieldType> = {
  employeeId: string;
  sections: Array<EmployeeDataSection<TState, TFieldType>>;
  relatedLists: Array<EmployeeDataRelatedList<TState, TFieldType>>;
};

export type EmployeeDataSection<TState, TFieldType> = {
  employeeId: string;
  label: string;
  fields: Array<EmployeeDataField<TState, TFieldType>>;
};

export type EmployeeDataRelatedList<TState, TFieldType> = {
  label: string;
  objectType: string;
  records: EmployeeDataRelatedListRecord<TState, TFieldType>[];
};

export type EmployeeDataRelatedListRecord<TState, TFieldType> = {
  id: string;
  fields: EmployeeDataField<TState, TFieldType>[];
};

export type EmployeeDataField<TState, TFieldType> =
  DataFieldSalesforceResponse & {
    fieldInfo: FieldInfo<TFieldType>;
    parentId: string;
    employeeId: string;
    type: TState;
    locale: string;
    value: string | null;
    oldValue: string | null;
  };

export type EmployeeDataPicklistEntrySalesforceResponse = {
  label: string;
  value: string;
};

export type FieldInfo<TFieldType> = {
  objectType: string;
  name: string;
  locale: string;
  label: string;
  recordTypeId: string | null;
  type: TFieldType;
  picklistValues: Array<FieldInfoPickListEntry>;
};

export type FieldInfoPickListEntry = {
  label: string;
  value: string;
};

export type UpdateFieldValue<TFieldType extends FieldType> = {
  name: string;
  type: TFieldType;
  value: string | null;
};

export enum FieldType {
  Address = 'ADDRESS',
  Anytype = 'ANYTYPE',
  Base64 = 'BASE64',
  Boolean = 'BOOLEAN',
  Combobox = 'COMBOBOX',
  Currency = 'CURRENCY',
  Datacategorygroupreference = 'DATACATEGORYGROUPREFERENCE',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Double = 'DOUBLE',
  Email = 'EMAIL',
  Encryptedstring = 'ENCRYPTEDSTRING',
  Id = 'ID',
  Integer = 'INTEGER',
  Long = 'LONG',
  Multipicklist = 'MULTIPICKLIST',
  Percent = 'PERCENT',
  Phone = 'PHONE',
  Picklist = 'PICKLIST',
  Reference = 'REFERENCE',
  String = 'STRING',
  Textarea = 'TEXTAREA',
  Time = 'TIME',
  Url = 'URL',
}

export function isFieldType(src: unknown): src is FieldType {
  return Object.values(FieldType).includes(src as FieldType);
}

type PicklistFieldValuesResult = {
  picklistFieldValues: PicklistFieldValues;
};

type PicklistFieldValues = {
  [key: string]: PicklistField;
};

type PicklistField = {
  values: PicklistEntry[];
};

type PicklistEntry = {
  value: string;
};

const mapLocale = (locale: string) => {
  if (locale === 'de-ch') {
    return 'de';
  }

  return locale;
};

export const mapFromEmployeeDataResult = <TState, TFieldType>(
  result: EmployeeDataSalesforceResponse,
  locale: string,
): EmployeeData<TState, TFieldType> => {
  const buildEmployeeDataSections = (
    { employeeId, recordTypeId, sections }: EmployeeDataSalesforceResponse,
    locale: string,
  ): EmployeeDataSection<TState, TFieldType>[] =>
    sections?.map((section) => {
      const fields: EmployeeDataField<TState, TFieldType>[] =
        section.fields?.map((field) => {
          return mapToEmployeeDataField(
            {
              objectType: 'flair__Employee__c',
              parentId: employeeId,
              employeeId,
              locale,
              recordTypeId,
            },
            field,
          );
        }) ?? [];

      return {
        employeeId: employeeId,
        label: section.label,
        fields: fields,
      };
    }) ?? [];

  const buildEmployeeDataRelatedLists = (
    { employeeId, recordTypeId, relatedLists }: EmployeeDataSalesforceResponse,
    locale: string,
  ): EmployeeDataRelatedList<TState, TFieldType>[] =>
    relatedLists?.map((relatedList) => {
      const records: EmployeeDataRelatedListRecord<TState, TFieldType>[] =
        relatedList.records?.map((record) => {
          return {
            ...record,
            fields:
              record.fields?.map((field) =>
                mapToEmployeeDataField(
                  {
                    objectType: relatedList.objectType,
                    parentId: record.id,
                    employeeId,
                    locale,
                    recordTypeId,
                  },
                  field,
                ),
              ) ?? [],
          };
        }) ?? [];

      return {
        ...relatedList,
        records,
      };
    }) ?? [];

  const mapToEmployeeDataField = (
    {
      objectType,
      parentId,
      employeeId,
      locale,
      recordTypeId,
    }: {
      objectType: string;
      parentId: string;
      employeeId: string;
      locale: string;
      recordTypeId?: string;
    },
    field: DataFieldSalesforceResponse,
  ): EmployeeDataField<TState, TFieldType> => ({
    fieldInfo: {
      name: field.name,
      objectType,
      recordTypeId: recordTypeId ?? null,
      locale,
      label: field.label,
      type: field.type.toString() as any as TFieldType,
      picklistValues: field.picklistValues,
    },
    parentId,
    employeeId,
    value: field.value?.toString(),
    oldValue: field.oldValue?.toString(),
    state: field.state as any as TState,
    pendingApproval: field.pendingApproval,

    // todo: depricated fields, remove when not used
    name: field.name,
    locale,
    label: field.label,
    type: field.type.toString() as any,
    picklistValues: field.picklistValues,
  });

  return {
    ...result,
    sections: buildEmployeeDataSections(result, locale),
    relatedLists: buildEmployeeDataRelatedLists(result, locale),
  };
};

const filterPicklistValues =
  (conn: () => Connection) =>
  (
    result: EmployeeDataSalesforceResponse,
  ): Promise<EmployeeDataSalesforceResponse> => {
    if (result.recordTypeId) {
      return conn()
        .request(
          `/ui-api/object-info/flair__Employee__c/picklist-values/${result.recordTypeId}`,
        )
        .then((response) => {
          const { picklistFieldValues } = response as PicklistFieldValuesResult;

          return {
            ...result,
            sections: result.sections.map((section) => {
              return {
                ...section,
                fields: section.fields.map((field) => {
                  const picklistField = picklistFieldValues[field.name];

                  if (picklistField) {
                    const allowedValues = picklistField.values.map(
                      (v) => v.value,
                    );
                    return {
                      ...field,
                      picklistValues: field.picklistValues.filter((e) =>
                        allowedValues.includes(e.value),
                      ),
                    };
                  }
                  return field;
                }),
              };
            }),
          };
        })
        .catch(() => result);
    }

    return Promise.resolve(result);
  };

export const toUpdateObject = <TFieldType extends FieldType>(
  fields: UpdateFieldValue<TFieldType>[],
): { [key: string]: UpdateFieldValueType } => {
  return fields.reduce((result, field) => {
    result[field.name] = resolveFieldValue(field.type, field.value);
    return result;
  }, {} as { [key: string]: UpdateFieldValueType });
};

type UpdateFieldValueType = boolean | string | number | null;

export const resolveFieldValue = (
  type: FieldType,
  value: string | null,
): UpdateFieldValueType => {
  if (!value || isEmpty(value)) {
    return null;
  }

  switch (type) {
    case FieldType.Boolean: {
      return value === 'true';
    }
    case FieldType.Currency:
    case FieldType.Percent:
    case FieldType.Double:
    case FieldType.Integer:
    case FieldType.Long: {
      return parseFloat(value);
    }
    default: {
      return value;
    }
  }
};

// Salesforce DTO types
type EmployeeDataSalesforceResponse = {
  employeeId: string;
  recordTypeId?: string;
  sections: EmployeeDataSectionSalesforceResponse[];
  relatedLists?: EmployeeDataRelatedListSalesforceResponse[];
};

type EmployeeDataSectionSalesforceResponse = {
  label: string;
  fields: DataFieldSalesforceResponse[];
};

type EmployeeDataRelatedListSalesforceResponse = {
  label: string;
  objectType: string;
  records: EmployeeDataRelatedListRecordSalesforceResponse[];
};

type EmployeeDataRelatedListRecordSalesforceResponse = {
  id: string;
  fields: DataFieldSalesforceResponse[];
};

type DataFieldSalesforceResponse = {
  // deprecated fields
  name: string;
  label: string;
  type: any;
  picklistValues: EmployeeDataPicklistEntrySalesforceResponse[];
  // end of deprecated fields

  value: any;
  oldValue: any;
  state: any;
  pendingApproval: boolean;
};

// end of SF DTO types
