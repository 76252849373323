import styled from '@emotion/styled';
import React from 'react';
import i18next from 'i18next';
import { getExpenseStatusColor } from '../mappings';
import FlairIcon, {
  FlairIconName,
} from '../../../../../atomic/atoms/FlairIcon';
import { ExpenseStatus } from '../../../__generated__/graphql';

type Props = {
  status: ExpenseStatus;
};

type RenderInfo = {
  text: string;
  color: string;
  icon: FlairIconName;
};

export const ExpenseStatusComponent: React.FC<Props> = ({ status }) => {
  const info = getRenderInfo(status);
  return (
    <Container backgroundColor={info.color}>
      <FlairIcon className="me-2" color="black" size="sm" icon={info.icon} />
      <span>{info.text}</span>
    </Container>
  );
};

const getRenderInfo = (status: ExpenseStatus): RenderInfo => {
  const text = i18next.t(`expenses.approvalStatus.${status}`);
  const color = getExpenseStatusColor(status);
  switch (status) {
    case 'APPROVED':
      return {
        text,
        color,
        icon: 'checkmark-outline',
      };
    case 'DECLINED':
      return {
        text,
        color,
        icon: 'close-outline',
      };
    case 'REIMBURSED':
      return {
        text,
        color,
        icon: 'circle-notch-outline',
      };
    default:
      return {
        text,
        color,
        icon: 'circle-notch-outline',
      };
  }
};

const Container = styled.div<{ backgroundColor: string }>(
  ({ backgroundColor }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0 6px',
    borderRadius: 6,
    lineHeight: '20px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    backgroundColor: backgroundColor,
  }),
);
