import { FieldValues, useForm, UseFormOptions } from 'react-hook-form';
import useYupValidationResolver from '../../../../components/form/useYupValidationResolver';
import { recordTypeFormKey } from '../ExtraFields/logic';
import { useMemo } from 'react';
import { useDefaultRecordTypeName } from '../ExtraFields/useDefaultRecordTypeName';
import {
  ExtraFieldContextParam,
  ExtraFieldsSupportedObject,
} from '../ExtraFields';

export type AbsenceFormContext = {
  requireNote: boolean;
};

type Props<TFormValues extends FieldValues> = UseFormOptions<TFormValues> & {
  absenceFormContext: AbsenceFormContext;
  contextParams: ExtraFieldContextParam[];
};

const objectApiName = ExtraFieldsSupportedObject.Absence;

export const useAbsenceFieldsForm = <TFormValues extends FieldValues>({
  absenceFormContext,
  defaultValues,
  contextParams,
  ...props
}: Props<TFormValues>) => {
  const defaultRecordTypeName = useDefaultRecordTypeName(objectApiName);
  const defaultValuesWithRecordType: any = useMemo(() => {
    if (defaultRecordTypeName != null) {
      return {
        ...(defaultValues ?? {}),
        [recordTypeFormKey]: defaultRecordTypeName,
      };
    }
    return defaultValues;
  }, [defaultRecordTypeName, defaultValues]);

  const form = useForm<TFormValues>({
    ...props,
    mode: 'onChange',
    defaultValues: defaultValuesWithRecordType,
    validationResolver: useYupValidationResolver(
      props.validationSchema,
      absenceFormContext,
    ),
  });
  return form;
};
