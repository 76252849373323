import React from 'react';
import {
  ExtraFieldsSupportedObject,
  ExtraFieldsTableId,
  ExtraTableItemType,
  ExtraTableRenderSettings,
  RecordWithExtraFields,
} from './types';
import { useExtraTableColumns } from './useExtraTableColumns';
import { TableContent } from '../../manager/components/TableContent';
import { toInputValue } from './logic';
import { SkeletonTable } from '../Skeleton/Table';

type Props = {
  objectApiName: ExtraFieldsSupportedObject;
  renderSettings?: ExtraTableRenderSettings;
  records: RecordWithExtraFields[];
  tableId: ExtraFieldsTableId;
};

export const ExtraFieldsTable = ({
  records,
  objectApiName,
  renderSettings,
  tableId,
}: Props) => {
  const { columns, loading } = useExtraTableColumns({
    objectApiName,
    renderSettings,
    tableId,
  });
  if (loading) {
    return <Loading />;
  }
  const data = records.map(enrichWithExtraFields);
  return <TableContent data={data} columns={columns} />;
};

const Loading: React.FC = () => (
  <SkeletonTable className="card-table" columns={4} rows={15} />
);

function enrichWithExtraFields(
  record: RecordWithExtraFields,
): ExtraTableItemType {
  const extraFieldData = record.extraFields.reduce(
    (acc, extraField) => {
      acc[extraField.fieldApiName] = toInputValue(extraField);
      acc.rawExtraFields[extraField.fieldApiName] = extraField;
      return acc;
    },
    { rawExtraFields: {} } as ExtraTableItemType,
  );
  return {
    ...record,
    ...extraFieldData,
    id: record.Id,
  };
}
