import { useMemo } from 'react';
import { useObjectExtraFields } from './useObjectExtraFields';
import { ExtraFieldsSupportedObject } from './types';

export const useDefaultRecordTypeName = (
  objectApiName: ExtraFieldsSupportedObject,
): string | null => {
  const { objectExtraFields } = useObjectExtraFields(objectApiName);
  const defaultRecordTypeId = useMemo(() => {
    return (
      objectExtraFields?.availableRecordTypes.find((x) => x.IsDefault)?.Name ??
      null
    );
  }, [objectExtraFields]);
  return defaultRecordTypeId;
};
