import HourlyAbsenceForm, {
  FormData as HourlyFormData,
} from './HourlyAbsenceForm';

import { yupTimeSchema } from '../../../../initializers/yup';
import * as yup from 'yup';
import { AbsenceFormProps } from './AbsenceFormProps';
import { useExtraFieldsFormValidation } from '../ExtraFields/useExtraFieldsFormValidation';
import { ExtraFieldsSupportedObject } from '../ExtraFields';
import { extraFieldsContextParams } from '../ExtraFields';
import { FieldType } from 'server/src/apps/internal-shared/repositoriesShared/EmployeeDataRepositoryBase';
import { useMemo } from 'react';
import Loading from './Loading';
import React from 'react';
import { isSameDay } from 'date-fns';

const validationSchema = yup.object().shape<HourlyFormData>({
  startDate: yup.date().required(),
  startTime: yupTimeSchema().required(),
  endDate: yup
    .date()
    .required()
    .when(
      'startDate',
      (startDate: HourlyFormData['startDate'], schema: yup.DateSchema) => {
        if (startDate) {
          return schema.min(startDate);
        }

        return schema;
      },
    ),
  endTime: yupTimeSchema()
    .required()
    .when(
      ['startDate', 'startTime', 'endDate'],
      (
        startDate: HourlyFormData['startDate'],
        startTime: HourlyFormData['startTime'],
        endDate: HourlyFormData['endDate'],
        schema: yup.StringSchema,
      ) => {
        if (isSameDay(startDate, endDate) && startTime) {
          return schema.test({
            name: 'moreThan',
            message: (values) => ({
              key: 'validations.number.moreThan',
              values,
            }),
            params: { more: startTime },
            test: (current) => {
              return current > startTime;
            },
          });
        }

        return schema;
      },
    ),
  note: yup
    .string()
    .notRequired()
    .when('$requireNote', (requireNote, schema) => {
      if (requireNote) {
        return schema.required();
      }
      return schema;
    }),
});

export const HourlyAbsenceFormWithExtraFields: React.FC<AbsenceFormProps> = (
  props,
) => {
  const { applyExtraFieldsValidationSchema, loading } =
    useExtraFieldsFormValidation({
      objectApiName: ExtraFieldsSupportedObject.Absence,
      contextParams: [
        {
          name: extraFieldsContextParams.absenceRequestForm.absenceCategoryName,
          type: FieldType.String,
          value: props.categoryName,
        },
      ],
    });

  const validationSchemaWithExtraFields = useMemo(() => {
    return applyExtraFieldsValidationSchema(validationSchema);
  }, [applyExtraFieldsValidationSchema]);

  if (loading) {
    return (
      <Loading
        absenceCategoriesGroupSelect={props.absenceCategoriesGroupSelect}
        absenceCategorySelect={props.absenceCategorySelect}
      />
    );
  }

  // it is super important to never change validationSchemaWithExtraFields property, otherwise validation is broken

  return (
    <HourlyAbsenceForm
      {...props}
      validationSchema={validationSchemaWithExtraFields}
    />
  );
};
