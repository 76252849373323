import {
  ExtraFieldFormInfoFragment,
  ObjectExtraFieldsFragment,
} from '../../__generated__/graphql';
import { ExtraFieldContextParam } from './types';

type VisiblityContext = {
  recordTypeName: string | null;
  contextParams: ExtraFieldContextParam[];
};

export const visibilityRecordTypeName = '$formValues.recordTypeName';
export const contextParamsPrefix = '$contextParams';

export const specialOperators = ['$ne'] as const;
export type SpecialOperator = (typeof specialOperators)[number];

export type SpecialSchema = {
  operator: SpecialOperator;
  value: string | string[];
};

function parseSpecialSchema(schemaValue: unknown): SpecialSchema | null {
  if (typeof schemaValue === 'object' && schemaValue != null) {
    const keys = Object.keys(schemaValue);
    if (
      keys.length === 1 &&
      specialOperators.includes(keys[0] as SpecialOperator)
    ) {
      const operator = keys[0] as SpecialOperator;
      if (operator in schemaValue) {
        const value = schemaValue[operator];
        if (typeof value === 'string' || Array.isArray(value)) {
          return {
            operator,
            value,
          };
        }
      }
    }
  }
  return null;
}

export class ExtraFieldsVisibility {
  constructor(private readonly objectExtraFields: ObjectExtraFieldsFragment) {}

  isFieldVisible(
    {
      visibilityCondition,
    }: Pick<ExtraFieldFormInfoFragment, 'visibilityCondition'>,
    context: VisiblityContext,
  ): boolean {
    if (!visibilityCondition) {
      return true;
    }
    try {
      const visiblitySchema = JSON.parse(visibilityCondition);
      return this.calculateVisibility(visiblitySchema, context);
    } catch (e) {
      return true;
    }
  }

  private calculateVisibility(
    fieldVisiblitySchema: Record<string, any>,
    { recordTypeName, contextParams }: VisiblityContext,
  ): boolean {
    if (!fieldVisiblitySchema) {
      return true;
    }
    return Object.entries(fieldVisiblitySchema).every(([key, schemaValue]) => {
      if (key === visibilityRecordTypeName) {
        const schemaRecordTypeName = schemaValue;
        return this.testValue(schemaRecordTypeName, recordTypeName);
      } else if (this.isContextParam(key)) {
        const contextParam = contextParams.find(
          (param) => param.name === this.getParamName(key),
        );
        return this.testValue(schemaValue, contextParam?.value);
      }
      return true;
    });
  }

  private testValue(
    schemaValue: unknown,
    contextValue: string | undefined | null,
  ): boolean {
    if (Array.isArray(schemaValue)) {
      return schemaValue.includes(contextValue);
    } else if (typeof schemaValue === 'string') {
      return contextValue?.toLowerCase() === schemaValue?.toLowerCase();
    }
    const specialSchema = parseSpecialSchema(schemaValue);
    if (specialSchema) {
      if (specialSchema.operator === '$ne') {
        if (typeof specialSchema.value === 'string') {
          return (
            contextValue?.toLowerCase() !== specialSchema.value.toLowerCase()
          );
        } else if (Array.isArray(specialSchema.value)) {
          return specialSchema.value.every(
            (schemaValue) =>
              contextValue?.toLowerCase() !== schemaValue.toLowerCase(),
          );
        }
      }
    }
    return false;
  }

  private isContextParam(key: string): boolean {
    return key.startsWith(`${contextParamsPrefix}.`);
  }

  private getParamName(key: string): string {
    return key.replace(`${contextParamsPrefix}.`, '');
  }
}
