import React from 'react';
import { CellProps } from 'react-table';
import {
  ExtraFieldTableInfoFragment,
  FieldType,
} from '../../__generated__/graphql';
import { ExtraTableItemType } from './types';
import { EmployeeWithAvatar } from '../Employee';

const EMPTY_VALUE = '---';

export function defaultLookupRenderer(
  props: CellProps<ExtraTableItemType>,
  field: ExtraFieldTableInfoFragment,
) {
  const referenceFragment =
    props.row.original.rawExtraFields[field.fieldApiName]?.referenceTo;
  // referenceFragment can be null in case if object doesn't implement AnyObject interface, in that case we can return props.value which is usually referenceObject.Name
  if (!referenceFragment) {
    return props.value ? <div>{props.value}</div> : <div>{EMPTY_VALUE}</div>;
  }
  if (referenceFragment.__typename === 'Project') {
    return <div>{referenceFragment.flair__Name__c}</div>;
  } else if (referenceFragment.__typename === 'Colleague') {
    return (
      <EmployeeWithAvatar
        employee={{
          firstName: referenceFragment.flair__First_Name__c ?? '',
          lastName: referenceFragment.flair__Last_Name__c ?? '',
          avatarUrl: referenceFragment.avatar?.url,
        }}
      />
    );
  }
  return <div>{props.value}</div>;
}

export function defaultSimpleRenderer(
  props: CellProps<ExtraTableItemType>,
  field: ExtraFieldTableInfoFragment,
) {
  if (!field.fieldInfo) {
    return <div>Not supported render: {field.customFieldName}</div>;
  }
  switch (field.fieldInfo.type) {
    case FieldType.Textarea:
      return <div>{props.value}</div>;
    default:
      return <div>{props.value}</div>;
  }
}
