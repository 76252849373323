import React from 'react';
import { useObjectExtraFields } from '../useObjectExtraFields';
import { ExtraFieldsAreaContent, Loading } from './ExtraFieldsAreaContent';
import ServerError from '../../../../../components/ServerError';
import { ExtraFieldContextParam, ExtraFieldsSupportedObject } from '../types';

type Props = {
  objectApiName: ExtraFieldsSupportedObject;
  contextParams?: ExtraFieldContextParam[];
};

export const ExtraFieldsArea: React.FC<Props> = ({
  objectApiName,
  contextParams,
}) => {
  const { objectExtraFields, loading, error } =
    useObjectExtraFields(objectApiName);
  if (loading) return <Loading />;
  if (error) return <ServerError />;
  if (!objectExtraFields) return null;
  if (!objectExtraFields.form) return null;
  return (
    <ExtraFieldsAreaContent
      objectExtraFields={objectExtraFields}
      contextParams={contextParams}
    />
  );
};
