import React from 'react';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import { CarryOver, PreviousPeriodSummary } from '../types';
import { AllowanceListItemAccrued } from './AllowanceListItemAccrued';
import { AbsencesHint } from './AbsencesHint';
import { AllowanceListItem } from './AllowanceListItem';

const PreviousYearPeriodListItems: React.FC<{
  isUnlimited: boolean;
  carriedOverToNextYear: CarryOver[];
  summary: PreviousPeriodSummary;
}> = ({
  isUnlimited,
  carriedOverToNextYear,
  summary: { accrued, taken, takenAbsences, allowances, name: periodName },
}) => {
  const t = useNamespacedTranslation(
    'absences.cards.absenceCategories.listItem.previousYear',
  );

  const carriedOver = carriedOverToNextYear.reduce(
    (acc, { amount }) => acc + amount,
    0,
  );

  const lostAmount = carriedOverToNextYear.reduce(
    (acc, { lost }) => acc + lost,
    0,
  );
  return (
    <>
      <AllowanceListItemAccrued
        isUnlimited={isUnlimited}
        periodType="previous"
        amount={accrued}
        allowances={allowances}
      />
      <AllowanceListItem>
        <small className="d-flex">
          {t('taken')}
          <AbsencesHint absences={takenAbsences} periodName={periodName} />
        </small>
        <small>{taken}</small>
      </AllowanceListItem>
      {!isUnlimited && (
        <>
          <AllowanceListItem>
            <small>{t('carryover')}</small>
            <small>{carriedOver}</small>
          </AllowanceListItem>
          {lostAmount > 0 && (
            <AllowanceListItem>
              <small>{t('lost')}</small>
              <small>{lostAmount}</small>
            </AllowanceListItem>
          )}
        </>
      )}
    </>
  );
};

export default PreviousYearPeriodListItems;
