import { ColumnWithLooseAccessor } from 'react-table';
import {
  ExtraFieldsTable_ExtraFieldValueFragment,
  ExtraFieldsTable_WithExtraFieldsFragment,
  FieldType,
} from '../../__generated__/graphql';

// that file acts like a documentation for all supported context params for extra fields
export const extraFieldsContextParams = {
  absenceRequestForm: {
    absenceCategoryName: 'absenceCategoryName',
  },
} as const;

export type ExtraFieldContextParam = {
  name: string;
  type: FieldType;
  value: string;
};

/* eslint-disable no-unused-vars */
export enum ExtraFieldsSupportedObject {
  Expense = 'flair__Expense__c',
  Absence = 'flair__Absence__c',
}

export enum ExtraFieldsTableId {
  ExpensesMy = 'flair__Expense__c.MyExpenses',
  ExpensesManager = 'flair__Expense__c.ManagerExpenses',
}

export type ExtraTableRenderSettings = {
  columns: Record<string, ColumnRenderSettings>;
  hubPersistentColumns?: Record<string, ColumnRenderSettings>;
};

export type ColumnRenderSettings = Omit<
  ColumnWithLooseAccessor<ExtraTableItemType>,
  'accessor'
>;

// todo: rename
export type RecordWithExtraFields = { Id: string } & Record<string, any> &
  ExtraFieldsTable_WithExtraFieldsFragment;

export type ExtraTableItemType = {
  rawExtraFields: Record<
    string,
    ExtraFieldsTable_ExtraFieldValueFragment | null
  >;
} & { id: string } & RecordWithExtraFields;
