import React, { useContext } from 'react';
import { ListGroup } from 'react-bootstrap';
import CurrentYearPeriodListItems from './CurrentYearPeriodListItems';
import NextYearPeriodListItems from './NextYearPeriodListItems';
import PreviousYearPeriodListItems from './PreviousYearPeriodListItems';
import { CategoryAccordionBodyContext } from '../CategoryAccordionBodyContext';
import { AbsenceCategorySummary } from '../types';

type Props = {
  summary: AbsenceCategorySummary;
};

export const AllowanceListGroup: React.FC<Props> = ({ summary }) => {
  const { periodType } = useContext(CategoryAccordionBodyContext);

  const renderBody = () => {
    switch (periodType) {
      case 'next':
        return (
          <NextYearPeriodListItems
            isUnlimited={summary.isUnlimited}
            summary={summary.nextPeriod}
          />
        );

      case 'previous':
        return (
          <PreviousYearPeriodListItems
            isUnlimited={summary.isUnlimited}
            carriedOverToNextYear={summary.curPeriod.carriedOverFromPrevYear}
            summary={summary.prevPeriod}
          />
        );

      default:
        return (
          <CurrentYearPeriodListItems
            isUnlimited={summary.isUnlimited}
            summary={summary.curPeriod}
          />
        );
    }
  };

  return <ListGroup variant="flush">{renderBody()}</ListGroup>;
};
