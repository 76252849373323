import React, { useEffect, useState } from 'react';
import { useForm, FieldValues, OnSubmit, FormContext } from 'react-hook-form';

export type FormProps<T extends FieldValues> = {
  defaultValues?: T;
  isChangeable?: boolean;
  children: JSX.Element[] | JSX.Element;
  onSubmit: OnSubmit<T>;
  validationSchema?: any;
};

export const Form = <T extends FieldValues>({
  defaultValues,
  children,
  onSubmit,
  isChangeable,
  validationSchema,
}: FormProps<T>) => {
  const [state, setState] = useState<any>(defaultValues);
  const methods = useForm<T>({
    defaultValues: state,
    validationSchema,
  });
  const { handleSubmit, reset } = methods;

  //TODO remove this useEffect after refactoring steps validation
  useEffect(() => {
    if (!defaultValues || !isChangeable) return;
    setState((prevState: T) => {
      return { ...prevState, ...defaultValues };
    });
  }, [defaultValues, isChangeable]);

  useEffect(() => {
    if (!defaultValues || !isChangeable) return;
    reset(defaultValues);
  }, [defaultValues, isChangeable, reset]);

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
    </FormContext>
  );
};
