import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Datepicker } from '../../../../../components/form/Datepicker';
import { FormGroup } from '../../../../../components/form/FormGroup';
import { Input } from '../../../../../components/form/Input';
import InputLabel from '../../../../../components/form/InputLabel';
import { SelectDropdownControlled } from '../../../../../components/form/Selects';
import { CreatableDropdownControlled } from '../../../../../components/form/Selects/CreatableDropdownControlled';
import SubmitButton from '../../../../../components/form/SubmitButton';
import { CreatableDropdownOption } from '../../../../../components/Select/types';
import { useToasts } from '../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { FILE_SIZE_LIMIT, collectFiles } from '../../../../../utils/file';
import {
  ExtraFieldInput,
  useCreateExpenseMutation,
  useExpenseFormDataQuery,
} from '../../../__generated__/graphql';
import { mapExpenseCategory, mapMerchant, mapProject } from './mappings';
import { fileSizeFormatter } from '../../../../../utils/file';
import {
  ExtraFieldsArea,
  ExtraFieldsForm,
  ExtraFieldsSupportedObject,
} from '../../../components/ExtraFields';

type Props = {
  onSave: () => void;
};

type FormData = {
  expenseDate: Date;
  amount: number;
  merchant: string;
  category: string;
  project: string | null;
  description: string;
};

const formSchema = yup.object().shape<FormData>({
  expenseDate: yup.date().required(),
  amount: yup.number().required(),
  merchant: yup.string().required(),
  category: yup.string().required(),
  project: yup.string().nullable(),
  description: yup.string().required(),
});

type File = {
  fileName: string;
  fileBase64: string;
  size: number;
};

export const ExpenseSaveForm: React.FC<Props> = ({ onSave }) => {
  const [files, setFiles] = useState<File[]>([]);
  const { addSuccess, addError } = useToasts();
  const { t } = useTranslation();
  const errorHandler = useMutationErrorHandler();
  const { data, loading } = useExpenseFormDataQuery();
  const [createExpense] = useCreateExpenseMutation();

  const projects = data?.projects.map(mapProject) || [];
  const expenseCategories =
    data?.expenseCategories.map(mapExpenseCategory) || [];

  const [merchants, setMerchants] = useState<CreatableDropdownOption[]>([]);

  useEffect(() => {
    setMerchants(data?.merchants.map(mapMerchant) || []);
  }, [data]);

  const handleSubmit = async (
    formData: FormData,
    extraFields: ExtraFieldInput[],
  ) => {
    const merchant = merchants.find(
      (merchant) => merchant.value === formData.merchant,
    );

    return await createExpense({
      variables: {
        input: {
          amount: formData.amount,
          category: formData.category,
          description: formData.description,
          expenseDate: moment(formData.expenseDate).format('YYYY-MM-DD'),
          project: formData.project,
          merchantName: formData.merchant,
          merchantId:
            merchant && merchant.label !== merchant.value
              ? merchant.value
              : null,
          files: files.map((f) => ({
            fileContentBase64: f.fileBase64,
            fileName: f.fileName,
          })),
          extraFields,
        },
      },
    })
      .then(() => {
        addSuccess(t('expenses.form.createSuccessText'));
        onSave();
      })
      .catch(errorHandler);
  };

  const handleAddMerchant = useCallback(
    (value) => {
      setMerchants([...merchants, { label: value, value }]);
    },
    [merchants],
  );

  const onFileChange = async ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.validity.valid && target.files) {
      const files = await collectFiles(target.files);

      const filesTotalSize = files.reduce(
        (totalSize, file) => totalSize + file.size,
        0,
      );

      if (filesTotalSize > FILE_SIZE_LIMIT) {
        addError(t('expenses.form.sizeLimitError'));
        target.value = '';
      } else {
        setFiles(files);
      }
    }
  };

  return (
    <ExtraFieldsForm
      objectApiName={ExtraFieldsSupportedObject.Expense}
      onSubmit={handleSubmit}
      validationSchema={formSchema}>
      <Row>
        <Col className="px-lg-2" lg={6}>
          <Datepicker
            required
            name="expenseDate"
            label={t('expenses.form.fields.expenseDateLabel')}
          />
        </Col>
        <Col className="px-lg-2" lg={6}>
          <Input
            label={t('expenses.form.fields.amountLabel')}
            placeholder={t('expenses.form.fields.amountPlaceholder')}
            name="amount"
            size="md"
            required
          />
        </Col>
        <Col className="px-lg-2" lg={6}>
          <FormGroup>
            <InputLabel
              label={t('expenses.form.fields.merchantLabel')}
              required={true}
            />
            <CreatableDropdownControlled
              isLoading={loading}
              name="merchant"
              placeholder={t('expenses.form.fields.merchantPlaceholder')}
              options={merchants}
              onCreate={handleAddMerchant}
              heightMode="large"
            />
          </FormGroup>
        </Col>
        <Col className="px-lg-2" lg={6}>
          <FormGroup>
            <InputLabel
              label={t('expenses.form.fields.categoryLabel')}
              required={true}
            />
            <SelectDropdownControlled
              isLoading={loading}
              name="category"
              placeholder={t('expenses.form.fields.categoryPlaceholder')}
              options={expenseCategories}
              heightMode="large"
            />
          </FormGroup>
        </Col>
        <Col className="px-lg-2" lg={6}>
          <FormGroup>
            <Form.Label>{t('expenses.form.fields.projectLabel')}</Form.Label>
            <SelectDropdownControlled
              isLoading={loading}
              name="project"
              placeholder={t('expenses.form.fields.projectPlaceholder')}
              options={projects}
              heightMode="large"
            />
          </FormGroup>
        </Col>
        <Col className="px-lg-2" lg={6}>
          <FormGroup>
            <Form.Label>
              {t('expenses.form.fields.fileLabelWithSize', {
                size: fileSizeFormatter(FILE_SIZE_LIMIT),
              })}
            </Form.Label>
            <Form.Control
              id="docFile"
              type="file"
              multiple
              onChange={onFileChange}
            />
          </FormGroup>
        </Col>
        <Col className="px-lg-2" lg={12}>
          <Input
            name="description"
            type="textarea"
            label={t('expenses.form.fields.descriptionLabel')}
            minRows={4}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <ExtraFieldsArea objectApiName={ExtraFieldsSupportedObject.Expense} />
        </Col>
      </Row>
      <div className="text-center">
        <SubmitButton block>{t('expenses.form.submitLabel')}</SubmitButton>
      </div>
    </ExtraFieldsForm>
  );
};
