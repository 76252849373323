import { useMemo } from 'react';
import {
  ColumnRenderSettings,
  ExtraFieldsSupportedObject,
  ExtraFieldsTableId,
  ExtraTableItemType,
  ExtraTableRenderSettings,
} from './types';
import { useObjectExtraFields } from './useObjectExtraFields';
import { CellProps, Column } from 'react-table';
import { ExtraFieldTableInfoFragment } from '../../__generated__/graphql';
import { defaultSimpleRenderer } from './defaultCellRenderers';
import { defaultLookupRenderer } from './defaultCellRenderers';

type Props = {
  objectApiName: ExtraFieldsSupportedObject;
  renderSettings?: ExtraTableRenderSettings;
  tableId: ExtraFieldsTableId;
};

type Result = {
  columns: Column<ExtraTableItemType>[];
  loading: boolean;
};

export const useExtraTableColumns = ({
  objectApiName,
  renderSettings,
  tableId,
}: Props): Result => {
  const { objectExtraFields, loading } = useObjectExtraFields(objectApiName);
  const columns: Column<ExtraTableItemType>[] = useMemo(() => {
    if (!objectExtraFields) {
      return [];
    }
    // map to avoid duplicate keys
    const columnsFromSalesforceMap = objectExtraFields.table.reduce(
      (acc, field) => {
        if (!isFieldVisible(field, tableId)) {
          return acc;
        }
        const columnProps = renderSettings?.columns[field.fieldApiName];
        acc.set(field.fieldApiName, {
          ...columnProps,
          accessor: field.fieldApiName,
          Header: getHeaderTitle(columnProps, field),
          Cell: getCellRenderer(columnProps, field),
        });
        return acc;
      },
      new Map<string, Column<ExtraTableItemType>>(),
    );
    const columnsFromMetadata = Array.from(columnsFromSalesforceMap.values());
    const hubPersistentColumns = Object.entries(
      renderSettings?.hubPersistentColumns ?? {},
    ).map(([columnName, columnProps]) => {
      return {
        ...columnProps,
        accessor: columnName,
        Header: columnProps.Header ?? '',
      };
    });

    return [...columnsFromMetadata, ...hubPersistentColumns];
  }, [renderSettings, objectExtraFields, tableId]);
  return { columns, loading };
};

function isFieldVisible(
  field: ExtraFieldTableInfoFragment,
  tableId: ExtraFieldsTableId,
) {
  return field.tableId === null || field.tableId === tableId;
}

function getHeaderTitle(
  columnRender: ColumnRenderSettings | undefined,
  field: ExtraFieldTableInfoFragment,
) {
  return (
    columnRender?.Header ?? field.columnTitle ?? field.fieldInfo?.label ?? ''
  );
}

function getCellRenderer(
  columnRender: ColumnRenderSettings | undefined,
  field: ExtraFieldTableInfoFragment,
) {
  if (columnRender?.Cell) {
    return columnRender.Cell;
  }
  return (props: CellProps<ExtraTableItemType>) => {
    return defaultCellRenderer(props, field);
  };
}

// we can implement custom logic for different types
// for example for employee we render avatar
function defaultCellRenderer(
  props: CellProps<ExtraTableItemType>,
  field: ExtraFieldTableInfoFragment,
) {
  if (field.fieldInfo?.referenceObjectApiName?.length) {
    return defaultLookupRenderer(props, field);
  }
  return defaultSimpleRenderer(props, field);
}
