import DailyAbsenceForm, {
  FormData as DailyFormData,
  HalfDay,
} from './DailyAbsenceForm';

import { AbsenceFormProps } from './AbsenceFormProps';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { useExtraFieldsFormValidation } from '../ExtraFields/useExtraFieldsFormValidation';
import { FieldType } from 'server/src/apps/internal-shared/repositoriesShared/EmployeeDataRepositoryBase';
import {
  extraFieldsContextParams,
  ExtraFieldsSupportedObject,
} from '../ExtraFields/types';
import Loading from './Loading';

const validationSchema = yup.object().shape<DailyFormData>({
  startDate: yup.date().required(),
  startDateHalfDay: yup.mixed<HalfDay>().required().oneOf(['true', 'false']),
  endDate: yup
    .date()
    .required()
    .when(
      'startDate',
      (startDate: DailyFormData['startDate'], schema: yup.DateSchema) => {
        if (startDate) {
          return schema.min(startDate);
        }

        return schema;
      },
    ),
  endDateHalfDay: yup.mixed<HalfDay>().required().oneOf(['true', 'false']),
  note: yup
    .string()
    .notRequired()
    .when('$requireNote', (requireNote, schema) => {
      if (requireNote) {
        return schema.required();
      }
      return schema;
    }),
});

export const DailyAbsenceFormWithExtraFields: React.FC<AbsenceFormProps> = (
  props,
) => {
  const { applyExtraFieldsValidationSchema, loading } =
    useExtraFieldsFormValidation({
      objectApiName: ExtraFieldsSupportedObject.Absence,
      contextParams: [
        {
          name: extraFieldsContextParams.absenceRequestForm.absenceCategoryName,
          type: FieldType.String,
          value: props.categoryName,
        },
      ],
    });

  const validationSchemaWithExtraFields = useMemo(() => {
    return applyExtraFieldsValidationSchema(validationSchema);
  }, [applyExtraFieldsValidationSchema]);

  if (loading) {
    return (
      <Loading
        absenceCategoriesGroupSelect={props.absenceCategoriesGroupSelect}
        absenceCategorySelect={props.absenceCategorySelect}
      />
    );
  }
  // it is super important to never change validationSchemaWithExtraFields property, otherwise validation is broken
  return (
    <DailyAbsenceForm
      {...props}
      validationSchema={validationSchemaWithExtraFields}
    />
  );
};
