import { AbsenceCategorySummary, CarryOver, PeriodType } from './types';
import {
  diff,
  formatDate,
  parseDate,
  UnitTypeArgs,
} from '../../../../../../utils/dateUtils';
import { getDate, getMonth } from 'date-fns';

export const getMainPeriodLabelAmount = (
  summary: AbsenceCategorySummary,
  periodType: PeriodType,
): number | null => {
  switch (periodType) {
    case 'next':
      return summary.nextPeriod.upcoming;
    case 'previous':
      return summary.prevPeriod.taken;
    case 'current':
      return summary.curPeriod.available;
  }
};

export const getExpiringCarryOvers = (
  now: Date,
  carryOvers: CarryOver[],
  expireInDaysThreshold: number,
): CarryOver[] => {
  return carryOvers.filter((carryOver) => {
    if (carryOver.expired > 0) {
      // already expired
      return null;
    }
    const expire = expireInDays(now, carryOver);
    if (carryOver.amount - carryOver.used <= 0) {
      // all used, nothing to expire
      return false;
    }
    return expire !== null && expire > 0 && expire < expireInDaysThreshold;
  });
};

export const expireInDays = (
  now: Date,
  carryOver: CarryOver,
): number | null => {
  if (!carryOver.expireOn) {
    return null;
  }
  return Math.max(0, diff(new Date(carryOver.expireOn), now, 'day'));
};

export type Period = { start: Date; end: Date };

export const createYearPeriods = (summary: {
  previousPeriod: {
    startDate: string;
    endDate: string;
  };
  currentPeriod: {
    startDate: string;
    endDate: string;
  };
  nextPeriod: {
    startDate: string;
    endDate: string;
  };
}): Period[] => {
  // todo: remove
  // const periodInfo = createPeriodInfo(summary);
  // const startOfCurYear = changeDateByUnit(now, periodInfo);
  // const cur = {
  //   start: startOfCurYear,
  //   end: addDays(addDateByUnit(startOfCurYear, 1, periodInfo.unit), -1),
  // };
  // const prev = { start: addYears(cur.start, -1), end: addYears(cur.end, -1) };
  // const next = { start: addYears(cur.start, 1), end: addYears(cur.end, 1) };
  return [
    createPeriod(summary.previousPeriod),
    createPeriod(summary.currentPeriod),
    createPeriod(summary.nextPeriod),
  ];
};

type PeriodStr = { startDate: string; endDate: string };

const createPeriod = ({ startDate, endDate }: PeriodStr) => {
  const start = parseDate(startDate);
  const end = parseDate(endDate);
  return { start, end };
};

export const createPeriodInfo = (currentPeriod: PeriodStr): UnitTypeArgs => {
  const period = createPeriod(currentPeriod);
  if (isStartOfYear(period.start)) {
    return { unit: 'year' };
  } else {
    return { unit: 'customYear', customYearStartDay: period.start };
  }
};

export const formatPeriodName = (periodStr: PeriodStr) => {
  const { unit } = createPeriodInfo(periodStr);
  const { start, end } = createPeriod(periodStr);
  const res = { start: '', end: '' };
  if (unit === 'year') {
    res.start = formatDate(start, 'MMMM');
    res.end = formatDate(end, 'MMMM yyyy');
  } else if (unit === 'customYear') {
    res.start = formatDate(start, 'MMMM yyyy');
    res.end = formatDate(end, 'MMMM yyyy');
  }
  return `${res.start} - ${res.end}`;
};

// todo: remove
// export const getDefaultStartPeriod = (
//   now: Date,
//   customYearStartDay: Date | undefined,
// ) => {
//   const today = startOfDay(now);
//   if (!customYearStartDay) {
//     return startOfYear(today);
//   }
//   return startOfCustomYear(today, customYearStartDay);
// };

const isStartOfYear = (date: Date) => {
  return getMonth(date) === 0 && getDate(date) === 1;
};
