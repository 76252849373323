import classNames from 'classnames';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { groupCategoriesByGroup } from '../../../pages/Absences/GroupCategories';
import { CalendarEmployeeAbsenceCategory } from '../../AbsenceCalendar/types';
import { iconToCategoryTypeTheme } from '../../AbsenceCalendar/Utils';
import CategoryButton, { CategoriesGroupButton } from './CategoryButton';
import CategoryInfo from './CategoryInfo';

type Props = {
  categories?: ReadonlyArray<CalendarEmployeeAbsenceCategory>;
  readOnly?: boolean;
};

type CategoryProps = {
  category: CalendarEmployeeAbsenceCategory;
  readOnly?: boolean;
  categories: ReadonlyArray<CalendarEmployeeAbsenceCategory>;
};

export const Category: React.FC<CategoryProps> = ({
  category,
  categories,
  readOnly,
}) => {
  const { Name, flair__Icon__c, flair__Type__c } = category.category;
  const theme = iconToCategoryTypeTheme(flair__Icon__c);
  const availableAmount = category.allowancesSummary.availableAmount;

  return (
    <OverlayTrigger
      trigger="click"
      placement={category.allowances.length > 3 ? 'bottom' : 'top'}
      rootClose
      overlay={
        <Popover id={Name}>
          <CategoryInfo
            id={category.Id}
            name={Name}
            unlimited={category.flair__Unlimited__c}
            withoutBalance={category.category.flair__Without_Balance__c}
            allowances={category.allowances}
            theme={theme}
            readOnly={readOnly}
            categories={categories}
            type={flair__Type__c}
          />
        </Popover>
      }>
      <CategoryButton
        amount={availableAmount}
        unlimited={category.flair__Unlimited__c}
        withoutBalance={category.category.flair__Without_Balance__c}
        name={Name}
        theme={theme}
        type={flair__Type__c}
      />
    </OverlayTrigger>
  );
};

const Categories: React.FC<Props> = ({ categories, readOnly = false }) => {
  const groups = groupCategoriesByGroup<CalendarEmployeeAbsenceCategory>(
    categories || [],
  );

  const categoriesWithNoGroups = categories?.filter((c) => !c.category.group);

  return (
    <div
      className={classNames(
        'd-flex',
        'align-items-center',
        'flex-wrap',
        'gap-3',
      )}>
      {groups.map(
        (group) =>
          group.isGroup && (
            <div key={group.key}>
              <CategoriesGroupButton
                key={group.key}
                name={group.name || ''}
                categories={group.categories}
              />
            </div>
          ),
      )}
      {categoriesWithNoGroups?.map((category) => (
        <div key={category.Id}>
          <Category
            category={category}
            categories={categoriesWithNoGroups}
            readOnly={readOnly}
          />
        </div>
      ))}
    </div>
  );
};

export default Categories;
