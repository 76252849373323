import React, { useMemo } from 'react';
import { ExtraTableRenderSettings } from '../../components/ExtraFields';
import { Trans, useTranslation } from 'react-i18next';
import { useCurrencyFormatter } from '../../../../hooks/useCurrencyFormatter';
import FormattedDate from '../../../../components/date/FormattedDate';
import { ExpenseStatusInfo } from './types';
import { ExpenseStatusCell } from './Common/ExpenseStatusCell';
import { ExtraFieldsTable_ExtraFieldValueFragment } from '../../__generated__/graphql';
import styled from '@emotion/styled';
import { EmployeeWithAvatar } from '../../components/Employee';
import SoftBadge from '../../components/SoftBadge';
import { mapExpenseStatus } from './mappings';

export type Item = {
  id: string;
  showComments?: boolean;
};

type Props = {
  onItemClick: (item: Item) => void;
};

const EMPTY_VALUE = '---';

export const useExpenseTableRenderSettings = ({ onItemClick }: Props) => {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormatter();

  const columns: ExtraTableRenderSettings['columns'] = useMemo(() => {
    return {
      Name: {
        Header: <Trans t={t} i18nKey="expenses.table.header.name" />,
        Cell: ({ row, value }) => {
          return (
            <ExpenseNameContainer title={value} className="text-truncate">
              <a
                href="stub"
                onClick={(e) => {
                  e.preventDefault();
                  onItemClick({ id: row.original.id });
                }}>
                {value}
              </a>
            </ExpenseNameContainer>
          );
        },
      },
      flair__Merchant__c: {
        Header: <Trans t={t} i18nKey="expenses.table.header.merchant" />,
      },
      flair__Project__c: {
        Header: <Trans t={t} i18nKey="expenses.table.header.project" />,
      },
      flair__Expense_Category__c: {
        Header: <Trans t={t} i18nKey="expenses.table.header.category" />,
        Cell: ({ value }) => {
          return <div>{value ? value : EMPTY_VALUE}</div>;
        },
      },
      flair__Amount__c: {
        Header: <Trans t={t} i18nKey="expenses.table.header.amount" />,
        Cell: ({ row, value }) => {
          return (
            <div>
              {formatCurrency({
                value: value,
                currency: row.original.currencyIsoCode ?? '',
              })}
            </div>
          );
        },
      },
      flair__Expense_Date__c: {
        Header: <Trans t={t} i18nKey="expenses.table.header.expenseDate" />,
        Cell: ({ value }) => {
          return <FormattedDate day={value} format="short" />;
        },
      },
      flair__Status__c: {
        Header: <Trans t={t} i18nKey="expenses.table.header.status" />,
        Cell: ({ row }) => {
          const readonly = true;
          const id = row.original.id;
          const statusInfo: ExpenseStatusInfo = {
            expenseId: id,
            status: mapExpenseStatus({
              flair__Status__c: row.original.flair__Status__c,
              flair__Reimbursed__c: row.original.flair__Reimbursed__c,
            }),
            declineReason: null,
          };
          return (
            <ExpenseStatusCell
              value={statusInfo}
              readonly={readonly}
              updatingToStatus={null}
            />
          );
        },
      },
      flair__Submitted_By__c: {
        Header: <Trans t={t} i18nKey="expenses.table.header.employee" />,
        Cell: ({ value, row }) => {
          const submittedBy: ExtraFieldsTable_ExtraFieldValueFragment | null =
            row.original.rawExtraFields.flair__Submitted_By__c;
          const colleagueReference = submittedBy?.referenceTo;
          if (
            !colleagueReference ||
            colleagueReference.__typename !== 'Colleague'
          ) {
            return null;
          }
          return (
            <div className="d-flex align-items-center gap-2">
              <a
                href="stub"
                onClick={(e) => {
                  e.preventDefault();
                  onItemClick({ id: row.original.id });
                }}>
                <EmployeeWithAvatar
                  employee={{
                    firstName: colleagueReference.flair__First_Name__c ?? '',
                    lastName: colleagueReference.flair__Last_Name__c ?? '',
                    avatarUrl: colleagueReference.avatar?.url,
                  }}
                />
              </a>
              {row.original.isNew && (
                <SoftBadge variant="primary">
                  {t('expenses.table.newLabel')}
                </SoftBadge>
              )}
            </div>
          );
        },
      },
    };
  }, [formatCurrency, onItemClick, t]);

  return { columns };
};

const ExpenseNameContainer = styled.div({
  maxWidth: '16.875rem',
});
