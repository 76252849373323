import React from 'react';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import styled from '@emotion/styled';
import { formatDate } from '../../../../../../../utils/dateUtils';
import { Theme } from '../../../../../../../theme';
import { CurrentPeriodSummary } from '../types';
import { AllowanceListItemAccrued } from './AllowanceListItemAccrued';
import { AbsencesHint } from './AbsencesHint';
import { expireInDays, getExpiringCarryOvers } from '../helpers';
import { AllowanceListItem } from './AllowanceListItem';
import { CarriedOverHint } from './CarriedOverHint';

export type CurrentYearPeriodListItemsProps = {
  isUnlimited: boolean;
  summary: CurrentPeriodSummary;
};

const i18Path = 'absences.cards.absenceCategories.listItem.currentYear';

const WARN_ABOUT_EXPIRING_CARRY_OVERS_IN_DAYS = 30;

const CurrentYearPeriodListItems: React.FC<CurrentYearPeriodListItemsProps> = ({
  isUnlimited,
  summary,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const {
    taken,
    scheduled,
    carriedOverFromPrevYear,
    takenAbsences,
    scheduledAbsences,
  } = summary;

  const prevCarriedOver = carriedOverFromPrevYear.reduce(
    (acc, cur) => acc + cur.amount,
    0,
  );
  const prevUsed = carriedOverFromPrevYear.reduce((acc, cur) => cur.used, 0);
  const prevExpired = carriedOverFromPrevYear.reduce(
    (acc, cur) => acc + cur.expired,
    0,
  );
  const prevAvailable = Math.max(
    0,
    prevCarriedOver - prevUsed - prevExpired,
    0,
  );

  const now = new Date();

  const expireWarningsCarryOvers = getExpiringCarryOvers(
    now,
    carriedOverFromPrevYear,
    WARN_ABOUT_EXPIRING_CARRY_OVERS_IN_DAYS,
  );

  return (
    <>
      <AllowanceListItemAccrued
        isUnlimited={isUnlimited}
        periodType="current"
        amount={summary.accrued}
        allowances={summary.allowances}
      />

      <AllowanceListItem>
        <small className="d-flex">
          {t('taken')}
          <AbsencesHint absences={takenAbsences} periodName={summary.name} />
        </small>
        <small>{taken}</small>
      </AllowanceListItem>
      <AllowanceListItem>
        <small className="d-flex">
          {t('planned')}
          <AbsencesHint
            absences={scheduledAbsences}
            periodName={summary.name}
          />
        </small>
        <small>{scheduled}</small>
      </AllowanceListItem>
      {!isUnlimited && carriedOverFromPrevYear.length > 0 && (
        <>
          <AllowanceListItem>
            <small className="d-flex">
              {t('prevAvailable')}
              <CarriedOverHint
                carriedOverFromPrevYear={carriedOverFromPrevYear}
                available={prevAvailable}
              />
            </small>
            <small>{prevAvailable}</small>
          </AllowanceListItem>
        </>
      )}
      {expireWarningsCarryOvers.map((carryOver) => (
        <AllowanceListItem>
          <RedMessage>
            <small>
              {t('expireWarning', {
                count: carryOver.amount - carryOver.used,
                expireIn: expireInDays(now, carryOver) ?? 0,
                expirationDate: carryOver.expireOn
                  ? formatDate(carryOver.expireOn, 'do MMMM yyyy')
                  : '',
              })}
            </small>
          </RedMessage>
        </AllowanceListItem>
      ))}
    </>
  );
};

const RedMessage = styled.small`
  color: ${Theme.color.red1};
  .message {
    opacity: 0.5;
  }
`;

export default CurrentYearPeriodListItems;
