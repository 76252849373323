import { useMemo } from 'react';
import { useAllObjectsExtraFieldsQuery } from '../../__generated__/graphql';
import { ExtraFieldsSupportedObject } from './types';

export const useObjectExtraFields = (
  objectApiName: ExtraFieldsSupportedObject,
) => {
  // we query all object at once because it is cheap operation
  const { data, loading, error } = useAllObjectsExtraFieldsQuery({
    variables: {
      objectApiNames: Object.values(ExtraFieldsSupportedObject),
    },
  });
  const objectExtraFields = useMemo(
    () =>
      data?.extraFields.objectsExtraFields.find(
        (objectExtraFields) =>
          objectExtraFields.objectApiName === objectApiName,
      ),
    [data, objectApiName],
  );
  return { objectExtraFields, loading, error };
};
