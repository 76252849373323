import React, { useCallback, useMemo } from 'react';
import {
  FormProps,
  Form as CustomForm,
} from '../../../../components/form/Form';
import { FieldValues } from 'react-hook-form';
import { ExtraFieldInput } from '../../__generated__/graphql';
import { collectFormDataExtraFields, recordTypeFormKey } from './logic';
import { useExtraFieldsFormValidation } from './useExtraFieldsFormValidation';
import { useDefaultRecordTypeName } from './useDefaultRecordTypeName';
import { ExtraFieldContextParam, ExtraFieldsSupportedObject } from './types';
type Props<T extends FieldValues> = Omit<FormProps<T>, 'onSubmit'> & {
  onSubmit: (data: T, extraFields: ExtraFieldInput[]) => void;
  objectApiName: ExtraFieldsSupportedObject;
  contextParams?: ExtraFieldContextParam[];
};

export const ExtraFieldsForm = <T extends FieldValues>({
  objectApiName,
  contextParams,
  onSubmit,
  validationSchema,
  defaultValues: propsDefaultValues,
  ...restProps
}: Props<T>) => {
  const { applyExtraFieldsValidationSchema } = useExtraFieldsFormValidation({
    objectApiName,
    contextParams: contextParams ?? [],
  });

  const defaultRecordTypeName = useDefaultRecordTypeName(objectApiName);

  const handleSubmit = useCallback(
    (formData: T) => {
      const extraFields = collectFormDataExtraFields(formData);
      return onSubmit(formData, extraFields);
    },
    [onSubmit],
  );

  const defaultValues: any = useMemo(() => {
    if (defaultRecordTypeName != null) {
      return {
        ...(propsDefaultValues ?? {}),
        [recordTypeFormKey]: defaultRecordTypeName,
      };
    }
    return propsDefaultValues;
  }, [defaultRecordTypeName, propsDefaultValues]);

  return (
    <CustomForm
      onSubmit={handleSubmit}
      isChangeable={true} // allow to set default values
      defaultValues={defaultValues}
      validationSchema={applyExtraFieldsValidationSchema(validationSchema)}
      {...restProps}></CustomForm>
  );
};
