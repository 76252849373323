export function toHtmlInputStepAttribute(decimalPlaces: number | null) {
  if (decimalPlaces === null) {
    return undefined;
  }
  if (decimalPlaces === 0) {
    return '1';
  }
  const stepPart = '1'.padStart(decimalPlaces, '0');
  return `0.${stepPart}`;
}
