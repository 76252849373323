import { isToday } from 'date-fns';
import { Theme } from '../../../../theme';
import { ContentDocumentLink, DocumentFile, Expense, expenseSF } from './types';
import {
  ExpenseDataFragment,
  ExpenseStatus,
} from '../../__generated__/graphql';

export const mapExpense = (exp: expenseSF): Expense => {
  return {
    ...exp,
    id: exp.Id,
    description: exp.flair__Description__c ?? null,
    amount: exp.flair__Amount__c,
    expenseDate: new Date(exp.flair__Expense_Date__c),
    project: exp.project?.flair__Name__c || null,
    merchant: exp.merchant?.Name,
    category: exp.expenseCategory,
    status: exp.flair__Status__c,
    employee: exp.employee,
    documents: exp.ContentDocumentLinks
      ? exp.ContentDocumentLinks.map(mapDocumentFile)
      : [],
    declineReason: exp.flair__Decline_Reason__c || null,
    name: exp.Name,
    isNew: isToday(new Date(exp.CreatedDate)),
    currencyIsoCode: exp.CurrencyIsoCode,
    commentsCount: exp.commentsCount,
  };
};

export const mapDocumentFile = (link: ContentDocumentLink): DocumentFile => ({
  id: link.Id,
  fileName: link.ContentDocument.Title
    ? `${link.ContentDocument.Title}.${link.ContentDocument.FileExtension}`
    : link.ContentDocument.Title,
  downloadPublicUrl: link.downloadUrl,
});

export const getExpenseStatusColor = (status: ExpenseStatus): string => {
  if (status === 'UNKNOWN') {
    return Theme.expenses.status['PENDING'].color;
  }

  return Theme.expenses.status[status].color;
};

export const mapExpenseStatus = ({
  flair__Status__c,
  flair__Reimbursed__c,
}: Pick<
  ExpenseDataFragment,
  'flair__Status__c' | 'flair__Reimbursed__c'
>): ExpenseStatus => {
  if (flair__Reimbursed__c) return ExpenseStatus.Reimbursed;
  return flair__Status__c;
};
