import React, { useContext } from 'react';
import { AbsenceInfo } from '../types';
import { Trans, useTranslation } from 'react-i18next';
import AbsenceHint from './AbsenceHint';
import styled from '@emotion/styled';
import { formatDate } from '../../../../../../../utils/dateUtils';
import { CategoryAccordionBodyContext } from '../CategoryAccordionBodyContext';
import { useHourlyAbsenceAmountFormatter } from '../../../../../hooks/useHourlyAbsenceAmountFormatter';

type Props = {
  absences: AbsenceInfo[];
  periodName: string;
};

const i18nKey = 'absences.cards.absencesHint';

export const AbsencesHint: React.FC<Props> = ({ absences, periodName }) => {
  const { t } = useTranslation();
  const { isHourly } = useContext(CategoryAccordionBodyContext);
  const hourlyFormatter = useHourlyAbsenceAmountFormatter();

  if (!absences.length) {
    return null;
  }
  const totalAmount = absences.reduce((acc, cur) => acc + cur.amount, 0);

  return (
    <AbsenceHint
      text={
        <small>
          <Trans
            t={t}
            i18nKey={
              isHourly ? `${i18nKey}.message_hourly` : `${i18nKey}.message`
            }
            values={{
              count: isHourly ? hourlyFormatter(totalAmount) : totalAmount,
              period: periodName,
            }}
            components={[<strong />]}
          />
          <AbsencesList absences={absences} />
        </small>
      }
    />
  );
};

const AbsencesList: React.FC<{ absences: AbsenceInfo[] }> = ({ absences }) => {
  const { t } = useTranslation();
  const hourlyFormatter = useHourlyAbsenceAmountFormatter();

  const { isHourly } = useContext(CategoryAccordionBodyContext);

  const translationKey = isHourly
    ? `${i18nKey}.info_hourly`
    : `${i18nKey}.info`;

  return (
    <StyledList className="ps-3">
      {absences.map((item) => (
        <li key={item.absenceId}>
          <Trans
            t={t}
            i18nKey={translationKey}
            values={{
              startDate: formatDate(item.startDay, 'dd MMMM yyyy'),
              endDate: formatDate(item.endDay, 'dd MMMM yyyy'),
              count: isHourly ? hourlyFormatter(item.amount) : item.amount,
            }}
            components={[<strong />]}
          />
        </li>
      ))}
    </StyledList>
  );
};

const StyledList = styled.ul`
  list-style-type: none;
`;
