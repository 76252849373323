import React from 'react';
import { useWithExtraFieldsQuery } from '../../../__generated__/graphql';
import {
  ExtraFieldsViewAreaContent,
  Loading,
} from './ExtraFieldsViewAreaContent';
import { useObjectExtraFields } from '../useObjectExtraFields';
import ServerError from '../../../../../components/ServerError';
import { ExtraFieldsSupportedObject } from '../types';

type Props = {
  objectApiName: ExtraFieldsSupportedObject;
  recordId: string;
  columnsCount?: number;
};

export const ExtraFieldsViewArea: React.FC<Props> = ({
  objectApiName,
  recordId,
  columnsCount = 1,
}) => {
  const { objectExtraFields, loading, error } =
    useObjectExtraFields(objectApiName);
  const skipValueLoading =
    objectExtraFields !== undefined && objectExtraFields.form.length === 0;
  const {
    data,
    loading: valuesLoading,
    error: valuesError,
  } = useWithExtraFieldsQuery({
    variables: { recordId, objectApiName },
    skip: skipValueLoading,
  });

  if (loading || valuesLoading) return <Loading columnsCount={columnsCount} />;
  if (error || valuesError) return <ServerError />;

  if (!objectExtraFields) return null;
  if (!data) return null;

  const { extraFields, recordType } = data.extraFields.withExtraFields;

  return (
    <ExtraFieldsViewAreaContent
      objectExtraFields={objectExtraFields}
      values={extraFields}
      recordType={recordType}
      columnsCount={columnsCount}
    />
  );
};
