import { toISODateOnly } from '../../../../utils/dateUtils';
import {
  AvailableRecordTypeFragment,
  ExtraFieldInput,
  ExtraFieldValueFragment,
  FieldType,
} from '../../__generated__/graphql';

export const recordTypeFormKey = 'extraFields_recordTypeName';

export const collectFormDataExtraFields = (
  formData: Record<string, any>,
): ExtraFieldInput[] => {
  const result = Object.keys(formData)
    .filter((key) => key.startsWith('customerField:'))
    .map((key) => {
      const { fieldApiName, type } = parseFieldApiName(key);
      const parsedValue = parseInputValue(formData[key], type);
      return {
        fieldApiName,
        type,
        valueJson: parsedValue !== null ? JSON.stringify(parsedValue) : null,
      };
    });
  const recordTypeName = formData[recordTypeFormKey];
  if (recordTypeName) {
    result.push({
      fieldApiName: 'RecordTypeName',
      type: FieldType.Textarea,
      valueJson: JSON.stringify(recordTypeName),
    });
  }
  return result;
};

export const parseInputValue = (
  value: Date | string | null | boolean | undefined,
  fieldType: FieldType,
) => {
  if (value === null || value === '' || value === undefined) {
    return null;
  }
  switch (fieldType) {
    case FieldType.Currency:
    case FieldType.Percent:
    case FieldType.Double:
    case FieldType.Integer:
    case FieldType.Long: {
      return typeof value === 'string' ? parseFloat(value) : value;
    }
    case FieldType.Date: {
      return toISODateOnly(safeGetDate(value));
    }
    case FieldType.Datetime: {
      return safeGetDate(value).toISOString();
    }
    default: {
      return value;
    }
  }
};

export const toInputValue = ({
  jsonValue,
  type,
  displayValue,
}: ExtraFieldValueFragment): string | null => {
  if (jsonValue === null || jsonValue === undefined) {
    return null;
  }
  if (type === FieldType.Reference) {
    return displayValue;
  }
  try {
    const parsedValue = JSON.parse(jsonValue);
    if (typeof parsedValue === 'boolean') {
      return parsedValue.toString();
    }
    return parsedValue;
  } catch (error) {
    return null;
  }
};

export const getExtraFieldInputName = (
  fieldApiName: string,
  type: FieldType,
) => {
  return `customerField:${type}:${fieldApiName}`;
};

export const parseFieldApiName = (name: string) => {
  const parts = name.split(':');
  return {
    type: parts[1] as FieldType,
    fieldApiName: parts[2],
  };
};

// the bug can be cause by yup validation, sometimes it returns string instead of Date
const safeGetDate = (value: unknown): Date => {
  if (typeof value === 'string') {
    return new Date(value);
  } else if (value instanceof Date) {
    return value;
  }
  throw new Error('Invalid type: ' + typeof value);
};

export function hasSeveralRecordTypes(
  availableRecordTypes: readonly AvailableRecordTypeFragment[],
) {
  return availableRecordTypes.length >= 2;
}

export function getRecordType(
  recordTypeId: string,
  availableRecordTypes: readonly AvailableRecordTypeFragment[],
): string | null {
  const recordType = availableRecordTypes.find((rt) => rt.Id === recordTypeId);
  return recordType?.Name ?? null;
}
