import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlledDateInput } from '../../../../components/form/Datepicker';
import { ControlledInput } from '../../../../components/form/Input';
import { useToasts } from '../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { currentYearPeriod, toMonthRange } from '../../../../utils/date';
import { isAfter } from 'date-fns';
import * as yup from 'yup';

import {
  useEmployeeAbsencesRefetchOption,
  useMyAbsencesRefetchOption,
} from '../../pages/Absences/useMyAbsencesRefetchOption';
import {
  AbsenceType,
  FieldType,
  RequestAbsenceInput,
  useRequestAbsenceMutation,
  useValidateRequestAbsenceMutation,
} from '../../__generated__/graphql';
import { ModalSidebarFormContent } from '../ModalSidebar';
import { AbsenceFileField, FileInput } from './AbsenceFileField';
import { AbsenceFormProps } from './AbsenceFormProps';
import FormBody from './FormBody';
import FormButtons from './FormButtons';
import { isDocumentUploadVisible } from './helper';
import OverlappingAbsences from './OverlappingAbsences';
import RequestingAmountResult from './RequestingAmountResult';
import SpecialRulesCard from './SpecialRulesCard';
import { RecordedLoomVideo } from '../LoomVideo';
import { Maybe } from '../../../../utils/maybe';
import { useUserInfo } from '../../context/UserInfo';
import {
  ExtraFieldContextParam,
  ExtraFieldsArea,
  extraFieldsContextParams,
  ExtraFieldsSupportedObject,
} from '../ExtraFields';
import { collectFormDataExtraFields } from '../ExtraFields/logic';
import {
  AbsenceFormContext,
  useAbsenceFieldsForm,
} from './useAbsenceFieldsForm';

export type HalfDay = 'true' | 'false';

export type FormData = {
  startDate: Date;
  startDateHalfDay: HalfDay;
  endDate: Date;
  endDateHalfDay: HalfDay;
  note?: string;
  loomVideo?: Maybe<RecordedLoomVideo>;
};

const absenceFormContext: AbsenceFormContext = {
  requireNote: false,
};

const defaultValues = {
  startDateHalfDay: 'false' as HalfDay,
  endDateHalfDay: 'false' as HalfDay,
};

const mapFormToInput = (
  formData: FormData & { attachedFile?: FileInput | null } & Pick<
      AbsenceFormProps,
      'categoryId' | 'policyId'
    >,
): RequestAbsenceInput => {
  const {
    categoryId,
    policyId,
    startDate,
    startDateHalfDay,
    endDate,
    endDateHalfDay,
    note,
    attachedFile,
    loomVideo,
  } = formData;
  return {
    flair__Employee_Absence_Category__c: categoryId,
    flair__Employee_Absence_Request_Policy__c: policyId,
    flair__Start_Date__c: moment(startDate).format('YYYY-MM-DD'),
    flair__Start_Time__c: startDateHalfDayToTime(startDateHalfDay),
    flair__End_Date__c: moment(endDate).format('YYYY-MM-DD'),
    flair__End_Time__c: endDateHalfDayToTime(endDateHalfDay),
    flair__Comment__c: note ?? null,
    attachedFile: attachedFile ?? null,
    loomVideo: loomVideo ?? null,
    extraFields: collectFormDataExtraFields(formData),
  };
};

const HALF_DAY_TIME = '12:00:00';

const startDateHalfDayToTime = (halfDay: HalfDay): string =>
  halfDay === 'true' ? HALF_DAY_TIME : '00:00:00';

const endDateHalfDayToTime = (halfDay: HalfDay): string =>
  halfDay === 'true' ? HALF_DAY_TIME : '23:59:59';

type Props = AbsenceFormProps & {
  validationSchema: yup.ObjectSchema<FormData>;
};

const DailyAbsenceForm: React.FC<Props> = ({
  categoryId,
  categoryName,
  policyId,
  unlimited,
  withoutBalance,
  onClose,
  specialRules,
  absenceCategoriesGroupSelect,
  absenceCategorySelect,
  accrualPolicyInterval,
  absenceRequestPolicySelect,
  absenceRequestPolicyRules,
  requireDocumentUpload,
  minAmountForDocumentUpload,
  requireNote,
  notePlaceholder,
  onSuccess,
  refetchQueries,
  validationSchema,
}) => {
  const { t } = useTranslation();
  const { addSuccess } = useToasts();
  const { id: meId } = useUserInfo();
  const { month, year: yearStr } = useQueryParams();
  const period = month ? toMonthRange(month) : undefined;
  const year = yearStr ? parseInt(yearStr) : undefined;
  const yearPeriod = Number.isInteger(year)
    ? currentYearPeriod(year)
    : undefined;
  const myAbsencesRefetchQuery = useMyAbsencesRefetchOption(period);
  const [requestAbsence] = useRequestAbsenceMutation({
    awaitRefetchQueries: true,
    /* eslint-disable-next-line no-restricted-syntax */
    refetchQueries: [
      myAbsencesRefetchQuery,
      ...useEmployeeAbsencesRefetchOption(meId, yearPeriod),
      ...(refetchQueries ?? []),
    ],
  });

  const errorHandler = useMutationErrorHandler();

  absenceFormContext.requireNote = requireNote;

  const contextParams: ExtraFieldContextParam[] = [
    {
      name: extraFieldsContextParams.absenceRequestForm.absenceCategoryName,
      type: FieldType.String,
      value: categoryName,
    },
  ];

  const form = useAbsenceFieldsForm<FormData>({
    defaultValues,
    contextParams,
    absenceFormContext,
    validationSchema,
  });

  const { watch } = form;
  const { startDate, startDateHalfDay, endDate, endDateHalfDay } = watch();

  const [loomVideo, setLoomVideo] = useState<Maybe<RecordedLoomVideo>>();

  const [absenceFile, setAbsenceFile] = useState<FileInput | null>(null);
  const [
    validateRequestAbsence,
    { loading: validating, data: validateRequestAbsenceData },
  ] = useValidateRequestAbsenceMutation();

  useEffect(() => {
    if (
      categoryId &&
      startDate &&
      startDateHalfDay &&
      endDate &&
      endDateHalfDay &&
      !isAfter(startDate, endDate)
    ) {
      validateRequestAbsence({
        variables: {
          input: mapFormToInput({
            categoryId,
            policyId,
            startDate,
            startDateHalfDay,
            endDate,
            endDateHalfDay,
          }),
        },
      });
    }
  }, [
    validateRequestAbsence,
    categoryId,
    policyId,
    startDate,
    startDateHalfDay,
    endDate,
    endDateHalfDay,
  ]);

  const validationResult =
    validateRequestAbsenceData?.absence.validateAbsenceRequest;

  const documentUploadVisible = isDocumentUploadVisible(
    requireDocumentUpload,
    minAmountForDocumentUpload,
    validationResult?.requestedAmount,
  );

  const overlappingAbsences =
    validationResult?.error?.__typename ===
    'ValidateAbsenceRequestOverlappingAbsencesError'
      ? validationResult.error.overlappingAbsences
      : [];

  const hasOverlappingAbsences = overlappingAbsences?.length > 0;
  const hasError = !!validationResult?.error;
  const hasInvalidFile = !!(documentUploadVisible && !absenceFile);
  const error = overlappingAbsences?.length
    ? t('requestAbsence.errors.overlappingAbsences', {
        count: overlappingAbsences.length,
      })
    : undefined;

  const onSubmit = useCallback(
    async (values: FormData) => {
      // block submit action
      if (hasError) {
        return;
      }

      await requestAbsence({
        variables: {
          input: mapFormToInput({
            ...values,
            categoryId,
            policyId,
            attachedFile: absenceFile,
            loomVideo: loomVideo,
          }),
        },
      })
        .then(() => {
          addSuccess(t('requestAbsence.successMessage'));
          onSuccess && onSuccess();
          onClose();
        })
        .catch(errorHandler);
    },
    [
      hasError,
      requestAbsence,
      categoryId,
      policyId,
      absenceFile,
      loomVideo,
      errorHandler,
      addSuccess,
      t,
      onSuccess,
      onClose,
    ],
  );

  return (
    <ModalSidebarFormContent
      form={form}
      onSubmit={onSubmit}
      body={
        <FormBody
          absenceCategoriesGroupSelect={absenceCategoriesGroupSelect}
          absenceCategorySelect={absenceCategorySelect}
          absenceRequestPolicySelect={absenceRequestPolicySelect}
          absenceRequestPolicyRules={absenceRequestPolicyRules}
          specialRules={
            specialRules ? (
              <SpecialRulesCard richText={specialRules} />
            ) : undefined
          }
          startDateInput={<ControlledDateInput name="startDate" />}
          startDateTimeInput={
            <ControlledInput type="select" name="startDateHalfDay">
              <option value="false">
                {t('requestAbsence.form.halfDayOptions.morning')}
              </option>
              <option value="true">
                {t('requestAbsence.form.halfDayOptions.lunch')}
              </option>
            </ControlledInput>
          }
          endDateInput={
            <ControlledDateInput
              name="endDate"
              options={{
                minDate: startDate,
              }}
            />
          }
          endDateTimeInput={
            <ControlledInput type="select" name="endDateHalfDay">
              <option value="true">
                {t('requestAbsence.form.halfDayOptions.lunch')}
              </option>
              <option value="false">
                {t('requestAbsence.form.halfDayOptions.evening')}
              </option>
            </ControlledInput>
          }
          fileInput={
            documentUploadVisible ? (
              <AbsenceFileField
                file={absenceFile}
                saving={validating}
                onSelectFile={setAbsenceFile}
              />
            ) : null
          }
          requireNote={requireNote}
          noteInput={
            <ControlledInput
              name="note"
              type="textarea"
              placeholder={notePlaceholder ?? ''}
            />
          }
          requestingAmountBadge={
            <RequestingAmountResult
              data={validationResult}
              loading={validating}
              type={AbsenceType.Daily}
              accrualPolicyInterval={accrualPolicyInterval}
              unlimited={unlimited}
              withoutBalance={withoutBalance}
            />
          }
          extraFields={
            <ExtraFieldsArea
              objectApiName={ExtraFieldsSupportedObject.Absence}
              contextParams={contextParams}
            />
          }
          overlappingAbsences={
            hasOverlappingAbsences ? (
              <OverlappingAbsences
                absences={overlappingAbsences}
                loading={validating}
              />
            ) : undefined
          }
          loomVideo={loomVideo}
          onRecordLoomVideo={setLoomVideo}
          onDeleteLoomVideo={() => setLoomVideo(null)}
        />
      }
      footer={
        <FormButtons
          disabled={hasError || validating || hasInvalidFile}
          error={error}
        />
      }
    />
  );
};

export default DailyAbsenceForm;
